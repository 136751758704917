import React, { useState, useEffect, useRef, useCallback } from "react";
import { withTranslation } from "react-i18next";

import { AgGridReact } from "ag-grid-react";

import { get } from "../utils/api";

import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";

function Leaderboard({ t }) {
  let [leaderboardData, setLeaderboardData] = useState([]);

  const [leaderboardColumnDefs] = useState([
    { field: "user", headerName: t("user.user", "User") },
    { field: "email", headerName: t("user.email", "Email") },
    {
      field: "today",
      headerName: t("common.today", "Today") + ": " + getToday(),
      type: "numericColumn",
    },
    {
      field: "past3days",
      headerName: t("common.past3days", "Past 3 days"),
      type: "numericColumn",
      sort: "desc",
    },
    {
      field: "alldays",
      headerName: t("common.alldays", "All time"),
      type: "numericColumn",
    },
  ]);

  const getLeaderboardData = useCallback(async () => {
    get(`/data/leaderboard/?date=${getToday()}`)
      .then((response) => {
        return response.data.map((d) => {
          return {
            user: d.first_name + " " + d.last_name,
            email: d.email,
            today: d.today,
            past3days: d.past3days,
            alldays: d.alldays,
          };
        });
      })
      .then(function (data) {
        setLeaderboardData(data);
      });
  }, []);

  const grid = useRef();
  const onGridReady = (params) => {
    grid.api = params.api;
    grid.api.sizeColumnsToFit();
  };

  useEffect(() => {
    getLeaderboardData();
  }, [getLeaderboardData]);

  function getToday() {
    let today = new Date();
    let year = today.getFullYear();
    let month = (1 + today.getMonth()).toString();
    month = month.length > 1 ? month : "0" + month;
    let day = today.getDate().toString();
    day = day.length > 1 ? day : "0" + day;
    return year + "-" + month + "-" + day;
  }

  return (
    <div className="mt-16">
      <div>
        <div className="sm:flex-auto">
          <h1 className="text-xl font-semibold text-gray-900">
            {t("common.leaderboard", "Leaderboard")}
          </h1>
          <p className="mt-2 mb-2 text-sm text-gray-700">
            {t(
              "common.numberOfRecordsForEachUserByDate",
              "Number of Records for each user by date",
            )}
          </p>
        </div>
        <div data-testid="leaderBoardRow">
          <div className="ag-theme-alpine" style={{ height: 400 }}>
            <AgGridReact
              ref={grid}
              rowData={leaderboardData}
              columnDefs={leaderboardColumnDefs}
              defaultColDef={{
                sortable: true,
                resizable: true,
              }}
              onGridReady={onGridReady}
            ></AgGridReact>
          </div>
        </div>
      </div>
    </div>
  );
}
export default withTranslation()(Leaderboard);
