import Landcover2Task from "./pages/Task";
import Landcover2ReviewSingle from "./pages/ReviewSingle";
import Landcover2ReviewSummary from "./pages/ReviewSummary";
import SummarySuperuser from "./pages/SummarySuperuser";

const routes = [
  { path: "task", element: Landcover2Task },
  { path: "review", element: Landcover2ReviewSummary },
  { path: "review/single", element: Landcover2ReviewSingle },
  { path: "superuser", element: SummarySuperuser },
];
export default routes;
