import React from "react";
import { Field } from "formik";
import { withTranslation } from "react-i18next";

function FirstNameInput({ errorData, t }) {
  return (
    <div className="sm:col-span-3">
      <label
        htmlFor="first_name"
        className="block text-sm font-semibold leading-6 text-gray-900"
      >
        {t("user.firstName", "First Name")}
      </label>
      <div className="mt-1">
        <Field
          type="text"
          name="first_name"
          id="first_name"
          className="mt-1 appearance-none text-cyan-900 bg-white border-gray-300 rounded-md block w-full px-3 h-10 shadow-sm sm:text-sm focus:outline-none placeholder:text-cyan-400 focus:ring-2 focus:ring-cyan-500"
        />
      </div>
      <span className="error text-red-700 text-xs">
        {(errorData && errorData.first_name) || "\u00A0"}
      </span>
    </div>
  );
}

export default withTranslation()(FirstNameInput);
