import React, { useState } from "react";
import { Formik, Form } from "formik";
import { useNavigate, Link } from "react-router-dom";
import { withTranslation } from "react-i18next";
import i18n from "i18next";

import { post, unwrapError } from "../../common/utils/api";
import { setLanguage } from "../../common/utils/helpers";
import Select from "../../common/components/form/Select.js";

import EmailInput from "../components/EmailInput.js";
import FirstNameInput from "../components/FirstNameInput.js";
import LastNameInput from "../components/LastNameInput.js";
import PasswordInput from "../components/PasswordInput.js";

function Signup({ showAlert, t }) {
  const navigate = useNavigate();
  const [errorData, setErrorData] = useState({});

  function onSubmit(value, { setSubmitting }) {
    setErrorData({});
    setLanguage(value.language);

    post("/users/register/", {
      email: value.email,
      first_name: value.first_name,
      last_name: value.last_name,
      password: value.password,
      password2: value.password2,
      language: value.language,
    })
      .then(function (response) {
        setErrorData({});
        showAlert({
          type: "success",
          message: t(
            "user.signUpSuccessful",
            "Sign up successful. Please check your email to verify ",
          ),
        });
        navigate("/");
      })
      .catch(function (error) {
        setErrorData(unwrapError(error, true));
      })
      .finally(() => setTimeout(() => setSubmitting(false), 300));
  }

  return (
    <div>
      <div className="mt-12 text-center text-2xl font-bold tracking-tight text-gray-900 lg:text-4xl">
        {t("user.signUpForAnAccount", "Sign up for an account")}
      </div>
      <Formik
        initialValues={{
          email: "",
          first_name: "",
          last_name: "",
          password: "",
          password2: "",
          language: i18n.language,
        }}
        onSubmit={onSubmit}
      >
        {({ isSubmitting }) => (
          <Form className="mt-6 mx-auto w-full max-w-sm">
            <p className="mb-6 text-center text-xs font-medium text-red-600">
              {errorData.non_field_errors || "\u00A0"}
            </p>
            <div className="mb-2">
              <EmailInput errorData={errorData} />
            </div>
            <div className="grid grid-cols-2 gap-x-4">
              <div className="mb-2">
                <FirstNameInput errorData={errorData} />
              </div>
              <div className="mb-2">
                <LastNameInput errorData={errorData} />
              </div>
              <div className="mb-2">
                <PasswordInput errorData={errorData} isConfirm={false} />
              </div>
              <div className="mb-2">
                <PasswordInput errorData={errorData} isConfirm={true} />
              </div>
            </div>
            <div className="mb-6">
              <Select
                label={"Language"}
                name={"language"}
                options={{ en: "English", fr: "French" }}
              />
            </div>
            <button
              type="submit"
              disabled={isSubmitting}
              className="inline-flex justify-center rounded-lg text-sm font-semibold py-2.5 px-4 bg-cyan-700 text-white hover:bg-cyan-600 w-full disabled:bg-gray-500 disabled:cursor-not-allowed"
            >
              <span>{t("user.signUp", "Sign up")}</span>
            </button>

            <Link
              to="/"
              className={`mt-1 inline-flex justify-center rounded-lg text-sm font-semibold py-2.5 px-4 border border-gray-300 text-gray-700 w-full ${
                isSubmitting
                  ? "bg-gray-100 cursor-not-allowed"
                  : "bg-white hover:bg-gray-50"
              }`}
            >
              Cancel
            </Link>
            <p className="mt-8 text-center text-gray-900">
              <Link to="/users/login" className="text-sm hover:underline">
                {t("user.alreadyHaveAnAccount", "Already have an account?")}
              </Link>
            </p>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default withTranslation()(Signup);
