import React, { useState, useEffect, useRef } from "react";
import { withTranslation } from "react-i18next";
import { Formik, Form, useFormikContext } from "formik";

import { get } from "../../common/utils/api";

import { AgGridReact } from "ag-grid-react";

import DateInput from "../../common/components/form/DateInput.js";
import Select from "../../common/components/form/Select.js";
import LoadingModal from "../../common/components/LoadingModal.js";
import {
  formatDate,
  getStandardizedQuestionCode,
} from "../../common/utils/helpers";

import { useUser } from "../../user/utils/user";

import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { useNavigate } from "react-router-dom";
import { calculatePercentage } from "../../common/utils/helpers";
import { questionCodeMapper } from "./ReviewDetail";

function ReviewForm({ user, userOptions, t }) {
  const { initialValues, submitForm } = useFormikContext();
  // Automatically refresh form if input values change
  useEffect(() => {
    if (initialValues) {
      submitForm();
    }
  }, [initialValues, submitForm]);
  return (
    <React.Fragment>
      <div className="space-y-6 pt-8 sm:space-y-5 sm:pt-10">
        <div className="space-y-6 sm:space-y-5">
          {user.user.is_superuser && (
            <Select
              label={"User email"}
              name={"formUser"}
              options={userOptions}
            />
          )}
          <DateInput label={"Start date"} name={"formStartDate"} />
          <DateInput label={"End date"} name={"formEndDate"} />
        </div>
      </div>
      <div className="py-5">
        <div className="flex justify-end">
          <button
            type="submit"
            className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-cyan-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2 disabled:bg-gray-500 disabled:cursor-not-allowed"
          >
            {t("common.generate", "Generate Report")}
          </button>
        </div>
      </div>
    </React.Fragment>
  );
}

export function isIncorrectReview(d) {
  return (d.answer_code !== 0) === d.is_approved && d.review_complete === true;
}

function groupByUsernameAndQuestionCode(data) {
  const incorrectReviews = data.filter((d) => {
    return isIncorrectReview(d);
  });

  const totalReviewsPerQuestionCode = data.reduce((acc, curr) => {
    const key = `${curr.username}::${getStandardizedQuestionCode(
      curr.question_code,
    )}`;
    if (!acc[key]) {
      acc[key] = { total: 0, completed: 0 };
    }
    acc[key].total += 1;
    if (curr.review_complete === true) {
      acc[key].completed += 1;
    }
    return acc;
  }, {});

  const groupedData = incorrectReviews.reduce((acc, curr) => {
    const key = `${curr.username}::${getStandardizedQuestionCode(
      curr.question_code,
    )}`;

    if (!acc[key]) {
      const code = getStandardizedQuestionCode(curr.question_code);
      acc[key] = {
        username: curr.username,
        question_code: code,
        question_code_decoded: questionCodeMapper[code],
        incorrectCount: 0,
        reviewCompleted: 0,
      };
    }
    acc[key].incorrectCount += 1;
    acc[key].reviewCompleted = totalReviewsPerQuestionCode[key].completed;

    return acc;
  }, {});

  let out = Object.values(groupedData);

  out.forEach((item) => {
    item.incorrect_percent = calculatePercentage(
      item.incorrectCount,
      item.reviewCompleted,
    );
  });

  return out;
}

function groupByUsernameAndDate(data) {
  // Group data by username and date
  const groupedData = data.reduce((acc, curr) => {
    const date = new Date(curr.updated_at).toISOString().split("T")[0]; // Extract date part
    const key = `${curr.username}::${date}`;
    if (!acc[key]) {
      acc[key] = [];
    }
    acc[key].push(curr);
    return acc;
  }, {});

  return Object.values(groupedData).map((group) => {
    const username = group[0].username;
    const date = new Date(group[0].updated_at).toISOString().split("T")[0];
    // Calculate incorrect reviews and other metrics
    const answers = group.length;
    const reviewCompleted = group.filter((d) => d.review_complete).length;
    const incorrectCount = group.filter((d) => isIncorrectReview(d)).length;

    return {
      username,
      date,
      answers,
      reviewCompleted,
      incorrectCount,
      reviewed_percent: calculatePercentage(reviewCompleted, answers),
      incorrect_percent: calculatePercentage(incorrectCount, reviewCompleted),
    };
  });
}

function groupByUsername(data, queueData) {
  const groupedData = data.reduce((acc, curr) => {
    const username = curr.username;
    const isIncorrect = isIncorrectReview(curr);
    if (!acc[username]) {
      acc[username] = {
        username: username,
        answers: 0,
        review_complete: 0,
        incorrectCount: 0,
        total_assigned: 0,
      };
    }
    acc[username].answers += 1;
    acc[username].total_assigned += 1;
    if (curr.review_complete) {
      acc[username].review_complete += 1;
    }
    if (isIncorrect) {
      acc[username].incorrectCount += 1;
    }

    return acc;
  }, {});
  queueData.forEach((item) => {
    const { username, count: questions_remaining } = item;
    if (groupedData[username]) {
      groupedData[username].total_assigned += questions_remaining;
    } else {
      groupedData[username] = {
        username: username,
        answers: 0,
        review_complete: 0,
        total_assigned: questions_remaining,
        incorrectCount: 0,
      };
    }
  });
  let out = Object.values(groupedData);

  out.forEach((item) => {
    item.reviewed_percent = calculatePercentage(
      item.review_complete,
      item.answers,
    );
    item.incorrect_percent = calculatePercentage(
      item.incorrectCount,
      item.review_complete,
    );
  });

  return out;
}

function ReviewerSummary({ showAlert, t }) {
  let [userOptions, setUserOptions] = useState([]);
  let [summaryData, setSummaryData] = useState([]);

  const navigate = useNavigate();
  const { user } = useUser();

  // Default date range: past three days for now
  let today = new Date();
  let daysBefore = new Date();
  daysBefore.setDate(today.getDate() - 3);

  // Use session storage for the existing user and dates if they exist
  const initialValues = {
    formUser: sessionStorage.getItem("user") || "All",
    formStartDate:
      sessionStorage.getItem("startDate") || formatDate(daysBefore),
    formEndDate: sessionStorage.getItem("endDate") || formatDate(today),
    formShowCompleted: sessionStorage.getItem("showCompleted") || "no",
  };

  const columnDefsByUsername = [
    { field: "username", headerName: t("user.user", "User"), width: 200 },
    {
      field: "total_assigned",
      headerName: "# Assigned",
      width: 150,
      type: "numericColumn",
    },
    {
      field: "answers",
      headerName: t("user.total", "# Answered"),
      width: 150,
      type: "numericColumn",
    },
    {
      field: "review_complete",
      headerName: t("user.review_complete", "# Reviewed"),
      width: 150,
      type: "numericColumn",
    },
    {
      field: "incorrectCount",
      headerName: t("Todo", "# Incorrect"),
      width: 150,
      type: "numericColumn",
    },
    {
      field: "reviewed_percent",
      headerName: t("user.review_complete", "% Reviewed"),
      width: 200,
      type: "numericColumn",
      valueFormatter: (params) => params.data.reviewed_percent.toFixed(2),
    },
    {
      field: "incorrect_percent",
      headerName: t("Todo", "% Incorrect"),
      width: 150,
      type: "numericColumn",
      valueFormatter: (params) => params.data.incorrect_percent.toFixed(2),
    },
  ];

  const columnDefsByDate = [
    { field: "username", headerName: t("user.user", "User"), width: 200 },
    { field: "date", headerName: t("common.date", "Date"), width: 150 },
    {
      field: "answers",
      headerName: t("user.total", "# Answered"),
      width: 150,
      type: "numericColumn",
    },
    {
      field: "reviewCompleted",
      headerName: t("user.review_complete", "# Reviewed"),
      width: 200,
      type: "numericColumn",
    },
    {
      field: "incorrectCount",
      headerName: t("Todo", "# Incorrect"),
      width: 150,
      type: "numericColumn",
    },
    {
      field: "reviewed_percent",
      headerName: t("user.review_complete", "% Reviewed"),
      width: 200,
      type: "numericColumn",
      valueFormatter: (params) => params.data.reviewed_percent.toFixed(2),
    },
    {
      field: "incorrect_percent",
      headerName: t("Todo", "% Incorrect "),
      width: 150,
      type: "numericColumn",
      valueFormatter: (params) => params.data.incorrect_percent.toFixed(2),
    },
  ];

  const generateColumnDefsByQuestions = (startDate, endDate) => [
    { field: "username", headerName: t("user.user", "User") },
    {
      field: "question_code",
      headerName: t("question.question", "Question"),
      type: "numericColumn",
      tooltipField: "question_code_decoded",
      cellClass: ["text-right", "cursor-pointer"],
    },
    {
      field: "reviewCompleted",
      headerName: t("user.review_complete", "# Reviewed"),
      type: "numericColumn",
    },
    {
      field: "incorrectCount",
      headerName: t("Todo", "# Incorrect"),
      type: "numericColumn",
      cellRenderer: (params) => params.value,
      onCellClicked: (params) => {
        navigate(
          `/data/reviewer/answer?user=${params.data.username}&question_code=${params.data.question_code}&start_date=${startDate}&end_date=${endDate}`,
        );
      },
      cellClass: [
        "text-red-600",
        "hover:text-red-900",
        "cursor-pointer",
        "text-right",
      ],
    },
    {
      field: "incorrect_percent",
      headerName: t("Todo", "% Incorrect"),
      type: "numericColumn",
      valueFormatter: (params) => params.data.incorrect_percent.toFixed(2),
    },
  ];

  const getUsers = async () => {
    get("/users/").then((response) => {
      let uniqueUsers = Object.assign(
        { All: "All" },
        ...response.data
          .sort(function (a, b) {
            return a.first_name.localeCompare(b.first_name);
          })
          .map((x) => ({
            [x.email]: x.first_name + " " + x.last_name + " (" + x.email + ")",
          })),
      );
      setUserOptions(uniqueUsers);
    });
  };

  const getData = async (
    user_selected,
    start_date,
    end_date,
    show_completed,
    setSubmitting,
  ) => {
    get(
      `/data/review/summary/?username=${user_selected}&start_date=${start_date}&end_date=${end_date}`,
    )
      .then((response) => response.data)
      .then((data) => {
        // Show most recent data first, with user as the tiebreaker
        let queueData = data.queue;

        let answerData = data.answers.sort(function (a, b) {
          let order1 = a.username?.localeCompare(b.username);
          let order2 = a.date?.localeCompare(b.date);

          if (order2 === 0) {
            return order1;
          } else {
            return -order2;
          }
        });
        setSummaryData({
          groupedByUsername: groupByUsername(answerData, queueData),
          groupedByDate: groupByUsernameAndDate(answerData),
          groupedByQuestions: groupByUsernameAndQuestionCode(answerData),
          columnDefsByQuestions: generateColumnDefsByQuestions(
            start_date,
            end_date,
          ),
        });
      })
      .finally(() => setTimeout(() => setSubmitting(false), 300));
  };

  const gridUsername = useRef(null);
  const gridDate = useRef(null);
  const gridQuestions = useRef(null);

  const onGridReady = (params, grid) => {
    grid.current = params.api;
    params.api.sizeColumnsToFit();
  };

  useEffect(() => {
    getUsers();
  }, []);
  const exportData = (grid) => {
    grid.current.exportDataAsCsv({
      allColumns: true,
      processCellCallback: (v) =>
        v.column.colDef.type === "combinedNumericColumn" ? v.value[0] : v.value,
    });
  };

  function onSubmit(value, { setSubmitting }) {
    getData(
      value.formUser,
      value.formStartDate,
      value.formEndDate,
      value.formShowCompleted,
      setSubmitting,
    );
    sessionStorage.setItem("user", value.formUser);
    sessionStorage.setItem("startDate", value.formStartDate);
    sessionStorage.setItem("endDate", value.formEndDate);
    sessionStorage.setItem("showCompleted", value.formShowCompleted);
  }

  return (
    <React.Fragment>
      <div className="mx-auto max-w-2xl px-4 sm:px-6 lg:px-8">
        <div className="mx-auto max-w-2xl px-4 sm:px-6 bg-white border-gray-200 shadow rounded-md pt-6">
          <div>
            <h3 className="text-lg font-medium leading-6 text-gray-900">
              {t("common.summary", "Generate Review Summary")}
            </h3>
          </div>
          <Formik
            enableReinitialize
            initialValues={initialValues}
            onSubmit={onSubmit}
          >
            {({ isSubmitting }) => (
              <React.Fragment>
                <LoadingModal open={isSubmitting} />
                <Form className="space-y-8 divide-y divide-gray-200">
                  <ReviewForm user={user} userOptions={userOptions} t={t} />
                </Form>
              </React.Fragment>
            )}
          </Formik>
        </div>
      </div>
      <div className="mt-8 flex flex-col">
        <div className="mx-auto max-w-2xl px-4 sm:px-6 lg:px-8">
          <div>
            <h3 className="text-lg font-medium leading-6 text-gray-900">
              {t("common.reviewSummary", "Overall Review Activity")}
            </h3>
          </div>
        </div>
        <div className="mb-2 flex justify-end">
          <button
            onClick={() => exportData(gridUsername)}
            className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-cyan-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2 disabled:bg-gray-500 disabled:cursor-not-allowed"
          >
            {t("common.exportToCSV", "Export to CSV")}
          </button>
        </div>
        <div data-testid="summaryByUser">
          <div className="ag-theme-alpine mt-4" style={{ height: 400 }}>
            <AgGridReact
              ref={gridUsername}
              rowData={summaryData.groupedByUsername}
              columnDefs={columnDefsByUsername}
              defaultColDef={{
                sortable: true,
                resizable: true,
              }}
              onGridReady={(params) => onGridReady(params, gridUsername)}
            />
          </div>
        </div>
      </div>
      <div className="mt-8 flex flex-col">
        <div className="mx-auto max-w-2xl px-4 sm:px-6 lg:px-8">
          <div>
            <h3 className="text-lg font-medium leading-6 text-gray-900">
              {t("common.reviewSummary", "Daily Review Activity")}
            </h3>
          </div>
        </div>
        <div className="mb-2 flex justify-end">
          <button
            onClick={() => exportData(gridDate)}
            className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-cyan-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2 disabled:bg-gray-500 disabled:cursor-not-allowed"
          >
            {t("common.exportToCSV", "Export to CSV")}
          </button>
        </div>
        <div data-testid="summaryByDate">
          <div className="ag-theme-alpine mt-4" style={{ height: 400 }}>
            <AgGridReact
              ref={gridDate}
              rowData={summaryData.groupedByDate}
              columnDefs={columnDefsByDate}
              defaultColDef={{
                sortable: true,
                resizable: true,
              }}
              onGridReady={(params) => onGridReady(params, gridDate)}
            />
          </div>
        </div>
      </div>
      <div className="mt-8 flex flex-col">
        <div className="mx-auto max-w-2xl px-4 sm:px-6 lg:px-8">
          <div>
            <h3 className="text-lg font-medium leading-6 text-gray-900">
              {t("common.reviewSummary", "Most Common Mistakes")}
            </h3>
          </div>
        </div>
        <div className="mb-2 flex justify-end">
          <button
            onClick={() => exportData(gridQuestions)}
            className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-cyan-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2 disabled:bg-gray-500 disabled:cursor-not-allowed"
          >
            {t("common.exportToCSV", "Export to CSV")}
          </button>
        </div>
        <div data-testid="summaryByQuestions">
          <div className="ag-theme-alpine mt-4" style={{ height: 400 }}>
            <AgGridReact
              ref={gridQuestions}
              rowData={summaryData.groupedByQuestions}
              columnDefs={summaryData.columnDefsByQuestions}
              defaultColDef={{
                sortable: true,
                resizable: true,
              }}
              onGridReady={(params) => onGridReady(params, gridQuestions)}
            />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
export default withTranslation()(ReviewerSummary);
