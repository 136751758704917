import React, { useState } from "react";
import Image from "../../common/components/Image.js";
import MapData from "./MapData.js";
import Question from "./Question.js";
import CropDetails from "./CropDetails.js";
const ImageData = ({
  question_type,
  crop_index,
  categorizedImages,
  onClick,
}) => {
  let imagesList = categorizedImages[crop_index][question_type];
  return (
    <>
      {imagesList?.map((resource, resource_index) => (
        <div className="relative w-full h-full snap-start" key={resource_index}>
          {resource.label && (
            <div className="m-2 absolute inline-flex items-center rounded-md border border-transparent bg-gray-900 opacity-80 px-3 py-2 text-sm font-medium leading-4 text-white shadow-sm">
              {resource.label}
            </div>
          )}
          <button
            className="h-60  w-full aspect-square block bg-origin-padding bg-left-top bg-cover bg-no-repeat z-0 shadow-inner"
            onClick={() => onClick(resource.imageUrl)}
            style={{
              backgroundImage: `url(${resource.imageUrl || ""})`,
            }}
          >
            <img
              data-testid={`image_${resource_index}`}
              src={resource.imageUrl || ""}
              alt={resource.title}
              className="w-full aspect-square hidden"
            />
          </button>
        </div>
      ))}
    </>
  );
};

function QuestionTypeGroup({
  question_type,
  allData,
  data,
  current,
  categorizedImages,
  crop_index,
  radioValues,
  handleRadioChange,
  questions,
}) {
  function onClick(e) {
    setUrl(e);
    setOpen(true);
  }

  const [url, setUrl] = useState("");
  const [open, setOpen] = useState(false);
  return (
    <>
      <div>
        <Image url={url} open={open} setOpen={setOpen} />
        <div className="container mx-auto col-span-2 md:col-span-1">
          <div className="relative overflow-hidden ">
            <div className="gap-1 relative flex flex-col overflow-auto z-0">
              {question_type === "location" ? (
                <MapData allData={allData} data={data} current={current} />
              ) : (
                <>
                  <ImageData
                    categorizedImages={categorizedImages}
                    question_type={question_type}
                    crop_index={crop_index}
                    onClick={onClick}
                  />
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="overflow-hidden bg-white col-span-3">
        <div className="bg-gray-200 px-5 py-3 ">
          <div className="text-sm">
            <dl>
              <dd>
                <div className="text-lg font-medium text-gray-900">
                  Questions related to {question_type}
                </div>
              </dd>
            </dl>
          </div>
        </div>
        <div className="py-2">
          <div className="flex items-center ">
            <div className="ml-5 w-0 flex-1">
              <dl className="space-y-2 ">
                {/* block of question */}
                {Object.entries(questions).map(
                  ([question_index, question_values]) => (
                    <Question
                      currentAnswer={radioValues[question_values.question_code]}
                      onClick={handleRadioChange}
                      questionCode={question_values.question_code}
                      key={question_index}
                    >
                      {/* block of data for certain question_type*/}
                      {question_type === "weight_value" &&
                        (() => {
                          let crop = data.crops[crop_index];
                          return (
                            <CropDetails crop={crop} crop_index={crop_index} />
                          );
                        })()}
                      {question_type === "plot" &&
                        (() => {
                          let crop = data.crops[crop_index];
                          return (
                            <li data-testid={`${crop_index}-cropDiagonal`}>
                              Diagonal: {crop.yield.diagonal}
                            </li>
                          );
                        })()}
                    </Question>
                  ),
                )}
              </dl>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default QuestionTypeGroup;
