import React, { useState, useEffect, useRef } from "react";

import { get, post } from "../../common/utils/api";
import { extractLabels } from "../utils/map";
import Map from "../components/Map";
import Palette from "../components/Palette";

const getTask = async () => {
  return get(`/landcover/task/`).then((response) => {
    return response.data;
  });
};

export default function DelineationTask() {
  const mapStatic = useRef(null);
  const mapInteractive = useRef(null);
  const categoryRef = useRef();

  const [task, setTask] = useState(null);

  async function submit() {
    const payload = {
      task: task.id,
      data: extractLabels(mapInteractive, task.grid.features),
    };
    return post("/landcover/task/submit/", payload);
  }

  const next = async (skip) => {
    if (task && !skip) {
      await submit();
    }

    const t = await getTask();
    t.grid = {
      type: "FeatureCollection",
      features: t.grid.geometries.map((g, i) => {
        return { type: "Feature", id: i, geometry: g };
      }),
    };
    setTask(t);
  };
  useEffect(() => {
    next();
  }, []);

  return (
    <React.Fragment>
      <div className="border-b border-gray-200 bg-white px-3 py-3 sm:px-6 rounded-md">
        <div className="-ml-4 -mt-2 flex flex-wrap items-center justify-between sm:flex-nowrap">
          <div className="ml-4 mt-2">
            <h3 className="text-md font-medium leading-6 text-gray-900">
              Color the grid on the target image according to the type of land
              in each square
            </h3>
          </div>
          <div className="ml-4 mt-2 flex-shrink-0">
            <div className="flex items-baseline">
              <div className="flex">{task && `Task ${task.id}`}</div>
              <button
                type="button"
                onClick={() => next(true)}
                className="inline-flex items-center rounded-md border border-transparent border-gray-300 bg-white px-3 py-2 text-sm font-medium leading-4 text-gray-700 shadow-sm hover:bg-gray-50 ml-4"
              >
                Skip
              </button>
              <button
                type="button"
                onClick={() => next(false)}
                className="inline-flex items-center rounded-md border border-transparent bg-cyan-600 px-3 py-2 text-sm font-medium leading-4 text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2 ml-4"
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
      {task && (
        <div className="mt-6 flex flex-row">
          <div className="flex-grow space-y-6">
            <div className="grid grid-cols-2 gap-4">
              <div>
                <div className="text-center font-medium text-lg text-gray-700">
                  Reference
                </div>
                <Map
                  map={mapStatic}
                  interactive={false}
                  task={task}
                  categoryRef={categoryRef}
                />
              </div>
              <div>
                <div className="text-center font-medium text-lg text-gray-700">
                  Target
                </div>
                <Map
                  map={mapInteractive}
                  interactive={true}
                  task={task}
                  categoryRef={categoryRef}
                />
              </div>
            </div>
          </div>

          <div className="mt-0 ml-6 flex-shrink-0 flex-grow-0">
            <Palette categoryRef={categoryRef} />
          </div>
        </div>
      )}
    </React.Fragment>
  );
}
