import "./index.css";
import React from "react";
import { createRoot } from "react-dom/client";
import reportWebVitals from "./reportWebVitals";

import { UserProvider } from "./user/utils/user";
import App from "./App";
import { setStandalone } from "./common/utils/helpers";
import { BrowserRouter } from "react-router-dom";

// Detect if the app was launched from the manifest so that we can track that
// the app has been installed, as we can't detect it automatically on firefox
// Ref https://gitlab.com/ground-truth/datacollectionfrontend/-/issues/26
const detectStandalone = () => {
  const urlSearchParams = new URLSearchParams(window.location.search);
  if (urlSearchParams.get("mode") === "standalone") {
    console.log("detected standalone launch");
    setStandalone(true);
  }
};
detectStandalone();

// Mount the app to the mount point.
const root = createRoot(document.getElementById("app"));
root.render(
  <React.StrictMode>
    <UserProvider>
      <BrowserRouter basename={process.env.PUBLIC_URL}>
        <App />
      </BrowserRouter>
    </UserProvider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
