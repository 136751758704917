import React, { useState, useEffect } from "react";
import { useFormikContext } from "formik";
import { Dialog } from "@headlessui/react";
import { withTranslation } from "react-i18next";

import Select from "../../common/components/form/Select.js";
import ImageInput from "../../common/components/form/ImageInput.js";
import TextInput from "../../common/components/form/TextInput.js";
import YieldInput from "./Yield.js";

export function getPhenologyOptions({ t }) {
  return {
    corn: {
      1: t("crop.corn1", "1 - Ungerminated"),
      2: t("crop.corn2", "2 - Germinated"),
      3: t("crop.corn3", "3 - Leaf appearance"),
      4: t("crop.corn4", "4 - 4 to 5 leaves stage"),
      5: t("crop.corn5", "5 - 6 to 10 leaves stage"),
      6: t("crop.corn6", "6 - Flowering"),
      7: t("crop.corn7", "7 - Grain filling and maturation"),
      8: t("crop.corn8", "8 - Harvesting"),
      9: t("crop.corn9", "9 - Harvested"),
    },
    rice: {
      1: t("crop.rice1", "1 - Ungerminated"),
      2: t("crop.rice2", "2 - Germinated"),
      3: t("crop.rice3", "3 - Seedling"),
      4: t("crop.rice4", "4 - Tillering"),
      5: t("crop.rice5", "5 - Reproductive"),
      6: t("crop.rice6", "6 - Heading/Ripening"),
      7: t("crop.rice7", "7 - Maturity"),
      8: t("crop.rice8", "8 - Harvesting"),
      9: t("crop.rice9", "9 - Harvested"),
    },
    cotton: {
      1: t("crop.cotton1", "1 - Ungerminated"),
      2: t("crop.cotton2", "2 - Germinated"),
      3: t("crop.cotton3", "3 - Leaf appearance"),
      4: t("crop.cotton4", "4 - 4 to 5 leaves stage"),
      5: t("crop.cotton5", "5 - Stem Elongation"),
      6: t("crop.cotton6", "6 - Floral boom"),
      7: t("crop.cotton7", "7 - Maturation stage"),
      8: t("crop.cotton8", "8 - Harvesting"),
      9: t("crop.cotton9", "9 - Harvested"),
    },
    cassava: {
      1: t("crop.cassava1", "1 - Planting and cutting"),
      2: t("crop.cassava2", "2 - Germination"),
      3: t("crop.cassava3", "3 - Leaf appearance"),
      4: t("crop.cassava4", "4 - Stem Elongation"),
      5: t("crop.cassava5", "5 - Floral boom"),
      6: t("crop.cassava6", "6 - Maturation stage"),
      7: t("crop.cassava7", "7 - Harvesting"),
      8: t("crop.cassava8", "8 - Harvested"),
    },
    soybean: {
      1: t("crop.soybean1", "1 - Sowing"),
      2: t("crop.soybean2", "2 - Germination"),
      3: t("crop.soybean3", "3 - True leaf stage"),
      4: t("crop.soybean4", "4 - Flowering"),
      5: t("crop.soybean5", "5 - Podding"),
      6: t("crop.soybean6", "6 - Senescence"),
      7: t("crop.soybean7", "7 - Maturity"),
      8: t("crop.soybean8", "8 - Harvesting"),
      9: t("crop.soybean9", "9 - Harvested"),
    },
    sesame: {
      1: t("crop.sesame1", "1 - Sowing"),
      2: t("crop.sesame2", "2 - Germination"),
      3: t("crop.sesame3", "3 - Vegetative stage"),
      4: t("crop.sesame4", "4 - Flowering"),
      5: t("crop.sesame5", "5 - Podding"),
      6: t("crop.sesame6", "6 - Ripening"),
      7: t("crop.sesame7", "7 - Maturity"),
      8: t("crop.sesame8", "8 - Harvesting"),
      9: t("crop.sesame9", "9 - Harvested"),
    },
    yam: {
      1: t("crop.yam1", "1 - Planting and cutting"),
      2: t("crop.yam2", "2 - Germination"),
      3: t("crop.yam3", "3 - Leaf appearance"),
      4: t("crop.yam4", "4 - Vine elongation"),
      5: t("crop.yam5", "5 - Floral boom"),
      6: t("crop.yam6", "6 - Maturity"),
      7: t("crop.yam7", "7 - Harvesting"),
      8: t("crop.yam8", "8 - Harvested"),
    },
    cowpea: {
      1: t("crop.cowpea1", "1 - Sowing"),
      2: t("crop.cowpea2", "2 - Germination"),
      3: t("crop.cowpea3", "3 - True leaf stage"),
      4: t("crop.cowpea4", "4 - Flowering"),
      5: t("crop.cowpea5", "5 - Podding"),
      6: t("crop.cowpea6", "6 - Senescence"),
      7: t("crop.cowpea7", "7 - Maturity"),
      8: t("crop.cowpea8", "8 - Harvesting"),
      9: t("crop.cowpea9", "9 - Harvested"),
    },
    veggies: {
      1: t("crop.veggies1", "1 - Transplanting stage"),
      2: t("crop.veggies2", "2 - Leaf formation"),
      3: t("crop.veggies3", "3 - Flowering"),
      4: t("crop.veggies4", "4 - Fruit development"),
      5: t("crop.veggies5", "5 - Fruit turning"),
      6: t("crop.veggies6", "6 - Maturity"),
      7: t("crop.veggies7", "7 - Harvesting"),
      8: t("crop.veggies8", "8 - Harvested"),
    },
    sweetpotato: {
      1: t("crop.sweetpotato1", "1 - Planting and cutting"),
      2: t("crop.sweetpotato2", "2 - Germination"),
      3: t("crop.sweetpotato3", "3 - Leaf appearance"),
      4: t("crop.sweetpotato4", "4 - Vine elongation"),
      5: t("crop.sweetpotato5", "5 - Floral boom"),
      6: t("crop.sweetpotato6", "6 - Maturity"),
      7: t("crop.sweetpotato7", "7 - Harvesting"),
      8: t("crop.sweetpotato8", "8 - Harvested"),
    },
    wheat: {
      1: t("crop.wheat1", "1 - Planting and transplanting"),
      2: t("crop.wheat2", "2 - Germination"),
      3: t("crop.wheat3", "3 - Seedling"),
      4: t("crop.wheat4", "4 - Tillering"),
      5: t("crop.wheat5", "5 - Jointing"),
      6: t("crop.wheat6", "6 - Booting"),
      7: t("crop.wheat7", "7 - Heading and Flowering"),
      8: t("crop.wheat8", "8 - Maturity"),
      9: t("crop.wheat9", "9 - Harvesting"),
      10: t("crop.wheat10", "10 - Harvested"),
    },
    sorghum: {
      1: t("crop.sorghum1", "1 - Planting"),
      2: t("crop.sorghum2", "2 - Germination"),
      3: t("crop.sorghum3", "3 - Leaf appearance"),
      4: t("crop.sorghum4", "4 - 4 to 5 leaves stage"),
      5: t("crop.sorghum5", "5 - 6 to 10 leaves stage"),
      6: t("crop.sorghum6", "6 - Panicle formation"),
      7: t("crop.sorghum7", "7 - Grain filling and maturation"),
      8: t("crop.sorghum8", "8 - Harvesting"),
      9: t("crop.sorghum9", "9 - Harvested"),
    },
    onion: {
      1: t("crop.onion1", "1 - Planting"),
      2: t("crop.onion2", "2 - Vegetative stage"),
      3: t("crop.onion3", "3 - Bulbing"),
      4: t("crop.onion4", "4 - Maturation"),
      5: t("crop.onion5", "5 - Harvesting"),
      6: t("crop.onion6", "6 - Harvested"),
    },
    potato: {
      1: t("crop.potato1", "1 - Planting"),
      2: t("crop.potato2", "2 - Sprouting"),
      3: t("crop.potato3", "3 - Vegetative stage"),
      4: t("crop.potato4", "4 - Underground Tuber Initiation"),
      5: t("crop.potato5", "5 - Tuber Enlargement"),
      6: t("crop.potato6", "6 - Maturation"),
      7: t("crop.potato7", "7 - Harvesting"),
      8: t("crop.potato8", "8 - Harvested"),
    },
    tomato: {
      1: t("crop.tomato1", "1 - Planting"),
      2: t("crop.tomato2", "2 - Vegetative stage"),
      3: t("crop.tomato3", "3 - Flowering"),
      4: t("crop.tomato4", "4 - Fruit"),
      5: t("crop.tomato5", "5 - Maturation"),
      6: t("crop.tomato6", "6 - Harvesting"),
      7: t("crop.tomato7", "7 - Harvested"),
    },
    apple: {
      1: t("crop.apple1", "1 - Planting"),
      2: t("crop.apple2", "2 - Vegetative stage"),
      3: t("crop.apple3", "3 - Tuberization and maturation"),
      4: t("crop.apple4", "4 - Harvesting"),
      5: t("crop.apple5", "5 - Harvested"),
    },
    beans: {
      1: t("crop.beans1", "1 - Germination"),
      2: t("crop.beans2", "2 - Seedling"),
      3: t("crop.beans3", "3 - Vegetative growth"),
      4: t("crop.beans4", "4 - Flowering"),
      5: t("crop.beans5", "5 - Pod Formation"),
      6: t("crop.beans6", "6 - Pod Development"),
      7: t("crop.beans7", "7 - Harvesting"),
      8: t("crop.beans8", "8 - Harvested"),
    },
    frenchbeans: {
      1: t("crop.frenchbeans1", "1 - Germination"),
      2: t("crop.frenchbeans2", "2 - Seedling"),
      3: t("crop.frenchbeans3", "3 - Vegetative growth"),
      4: t("crop.frenchbeans4", "4 - Flowering"),
      5: t("crop.frenchbeans5", "5 - Pod Formation"),
      6: t("crop.frenchbeans6", "6 - Pod Development"),
      7: t("crop.frenchbeans7", "7 - Harvesting"),
      8: t("crop.frenchbeans8", "8 - Harvested"),
    },
    bushbeans: {
      1: t("crop.bushbeans1", "1 - Germination"),
      2: t("crop.bushbeans2", "2 - Seedling"),
      3: t("crop.bushbeans3", "3 - Vegetative growth"),
      4: t("crop.bushbeans4", "4 - Flowering"),
      5: t("crop.bushbeans5", "5 - Pod Formation"),
      6: t("crop.bushbeans6", "6 - Pod Development"),
      7: t("crop.bushbeans7", "7 - Harvesting"),
      8: t("crop.bushbeans8", "8 - Harvested"),
    },
    climbingbeans: {
      1: t("crop.climbingbeans1", "1 - Germination"),
      2: t("crop.climbingbeans2", "2 - Seedling"),
      3: t("crop.climbingbeans3", "3 - Vegetative growth"),
      4: t("crop.climbingbeans4", "4 - Flowering"),
      5: t("crop.climbingbeans5", "5 - Pod Formation"),
      6: t("crop.climbingbeans6", "6 - Pod Development"),
      7: t("crop.climbingbeans7", "7 - Harvesting"),
      8: t("crop.climbingbeans8", "8 - Harvested"),
    },
    peanuts: {
      1: t("crop.peanuts1", "1 - Seeding"),
      2: t("crop.peanuts2", "2 - Germination"),
      3: t("crop.peanuts3", "3 - Beginning pod"),
      4: t("crop.peanuts4", "4 - Elongation"),
      5: t("crop.peanuts5", "5 - Flower Blooming"),
      6: t("crop.peanuts6", "6 - Development and Maturation"),
      7: t("crop.peanuts7", "7 - Maturity"),
      8: t("crop.peanuts8", "7 - Harvested"),
    },
    pepper: {
      1: t("crop.pepper1", "1 - Seedling"),
      2: t("crop.pepper2", "2 - Early Growth"),
      3: t("crop.pepper3", "3 - Flowering pod"),
      4: t("crop.pepper4", "4 - Fruiting"),
      5: t("crop.pepper5", "5 - Ripening"),
      6: t("crop.pepper6", "6 - Harvesting"),
      7: t("crop.pepper7", "7 - Harvested"),
    },
    millet: {
      1: t("crop.millet1", "1 - Emergence"),
      2: t("crop.millet2", "2 - Three-leaf"),
      3: t("crop.millet3", "3 - Five-leaf"),
      4: t("crop.millet4", "4 - Panicle initiation"),
      5: t("crop.millet5", "5 - Flag leaf"),
      6: t("crop.millet6", "6 - Dough"),
      7: t("crop.millet7", "7 - Physiological maturity"),
      8: t("crop.millet8", "8 - Harvesting"),
      9: t("crop.millet9", "9 - Harvested"),
    },
  };
}
export const isYieldCrop = (crop, t) =>
  Object.keys(getPhenologyOptions({ t })).includes(crop);
export const isPhenologyCrop = (crop, t) =>
  Object.keys(getPhenologyOptions({ t })).includes(crop);

function CropItem({ index, t, images }) {
  let [isOpen, setIsOpen] = useState(false);

  const cropsRequired = ["corn", "rice", "cassava"];
  const cropsWithLegend = ["corn", "rice", "cassava", "cotton"];
  const defaultPhenology = {
    1: 1,
    2: 2,
    3: 3,
    4: 4,
    5: 5,
    6: 6,
  };
  const [phenologyOptions, setPhenologyOptions] = useState(defaultPhenology);

  const sortedOptions = [
    { key: "coffee", text: t("crop.coffee", "Coffee") },
    { key: "cocoa", text: t("crop.cocoa", "Cocoa") },
    { key: "palmOilTree", text: t("crop.palmOilTree", "Palm oil tree") },
    { key: "cashew", text: t("crop.cashew", "Cashew") },
    { key: "cotton", text: t("crop.cotton", "Cotton") },
    { key: "mango", text: t("crop.mango", "Mango") },
    { key: "banana", text: t("crop.banana", "Banana") },
    { key: "hevea", text: t("crop.hevea", "Hevea") },
    { key: "pineapple", text: t("crop.pineapple", "Pineapple") },
    { key: "cola", text: t("crop.cola", "Cola") },
    { key: "shea", text: t("crop.shea", "Shea") },
    { key: "rice", text: t("crop.rice", "Rice") },
    { key: "corn", text: t("crop.corn", "Maize") },
    { key: "cassava", text: t("crop.cassava", "Cassava") },
    { key: "veggies", text: t("crop.veggies", "Veggies") },
    { key: "plantain", text: t("crop.plantain", "Plantain") },
    { key: "yam", text: t("crop.yam", "Yam") },
    { key: "fallow", text: t("crop.fallow", "Fallow land") },
    { key: "coconut", text: t("crop.coconut", "Coconut") },
    { key: "okra", text: t("crop.okra", "Okra") },
    { key: "orange", text: t("crop.orange", "Orange") },
    { key: "cowpea", text: t("crop.cowpea", "Cowpea") },
    { key: "soybean", text: t("crop.soybean", "Soybean") },
    { key: "cocoyam", text: t("crop.cocoyam", "Cocoyam") },
    { key: "sorghum", text: t("crop.sorghum", "Sorghum") },
    { key: "onion", text: t("crop.onion", "Onion") },
    { key: "tomato", text: t("crop.tomato", "Tomato") },
    { key: "sweetpotato", text: t("crop.sweetpotato", "Sweet potato") },
    { key: "apple", text: t("crop.apple", "Apple") },
    { key: "wheat", text: t("crop.wheat", "Wheat") },
    { key: "potato", text: t("crop.potato", "Potato") },
    { key: "sugarcane", text: t("crop.sugarcane", "Sugar cane") },
    { key: "beans", text: t("crop.beans", "Beans") },
    { key: "frenchbeans", text: t("crop.frenchbeans", "French beans") },
    { key: "bushbeans", text: t("crop.bushbeans", "Bush beans") },
    { key: "climbingbeans", text: t("crop.climbingbeans", "Climbing beans") },
    { key: "preparedland", text: t("crop.preparedland", "Prepared land") },
    { key: "peanuts", text: t("crop.peanuts", "Peanuts") },
    { key: "pepper", text: t("crop.pepper", "Pepper") },
    { key: "millet", text: t("crop.millet", "Millet") },
  ];

  // Sort options by display text
  sortedOptions.sort((a, b) => a.text.localeCompare(b.text));
  // Add "other" as last option
  sortedOptions.push({ key: "other", text: t("crop.other", "Other crop") });
  // Convert to input for select box
  const options = Object.fromEntries(sortedOptions.map((o) => [o.key, o.text]));

  const phenologyOptionsList = getPhenologyOptions({ t });

  function validateRequire(value) {
    let error;
    if (!value) {
      error = t("crop.requiredField", "Required field");
    }
    return error;
  }
  function validatePhenology(value) {
    let error;
    if (!value && cropsRequired.includes(crops[index].crop)) {
      error = t("crop.requiredField", "Required field");
    }
    return error;
  }
  const {
    values: { crops },
    handleChange,
  } = useFormikContext();

  const myHandleChange = (e) => {
    handleChange(e);
    setPhenologyOptions(
      e.target.value in phenologyOptionsList
        ? phenologyOptionsList[e.target.value]
        : defaultPhenology,
    );
  };

  // Initialize phenological stage if there is crop already for review page
  useEffect(() => {
    setPhenologyOptions(phenologyOptionsList[crops[index].crop]);
  }, []);

  return (
    <React.Fragment>
      <div data-testid="crop-items">
        <Select
          label={
            index === 0
              ? t("crop.crop", "Crop grown in this plot")
              : t(
                  "crop.additionalCropType",
                  "Additional crop type grown in the same plot",
                )
          }
          name={`crops[${index}].crop`}
          options={options}
          onChange={myHandleChange}
          validate={validateRequire}
          required={true}
          showLegend={false}
        />
      </div>
      {crops[index].crop === "other" && (
        <TextInput
          label={t(
            "crop.whatIsTheOtherCropType",
            "What is the other crop type?",
          )}
          name={`crops[${index}].otherCropType`}
          validate={validateRequire}
          required={true}
        />
      )}
      {crops[index].crop === "preparedland" && (
        <TextInput
          label={t(
            "crop.whatIsThePreparedCrop",
            "Which crop is going to be planted (if known)?",
          )}
          name={`crops[${index}].preparedCropType`}
          required={false}
        />
      )}
      {isPhenologyCrop(crops[index].crop, t) && (
        <React.Fragment>
          <Select
            label={t("crop.phenology", "Phenological stage as of today")}
            name={`crops[${index}].phenology`}
            options={phenologyOptions}
            validate={validatePhenology}
            required={cropsRequired.includes(crops[index].crop)}
            showLegend={cropsWithLegend.includes(crops[index].crop)}
            setIsOpen={setIsOpen}
          />

          <Dialog
            open={isOpen}
            className="relative z-50"
            onClose={() => setIsOpen(false)}
          >
            <div className="fixed inset-0 bg-black/30" aria-hidden="true" />
            <div className="fixed inset-0 z-10 overflow-y-auto">
              <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white shadow-xl lg:max-w-2xl sm:my-8 sm:w-full sm:m-8">
                  <img
                    className="mx-auto w-full rounded-lg bg-white sm:border-4 sm:border-gray-200"
                    src={`/phenology/${crops[index].crop}.jpg`}
                    alt=""
                  />
                </Dialog.Panel>
              </div>
            </div>
          </Dialog>
        </React.Fragment>
      )}
      {images ? (
        <ImageInput
          maxSize={2500}
          name={`crops[${index}].images`}
          label={t("plot.images", "Images")}
          startingValue={crops[index].images}
          required={true}
        />
      ) : null}
      <Select
        label={t(
          "crop.diseaseSoilDeficiency",
          "Are there any issues with this parcel? (e.g. disease, weeds, deficiencies)",
        )}
        name={`crops[${index}].disease`}
        options={{ yes: t("common.yes", "Yes"), no: t("common.no", "No") }}
      />
      {crops[index].disease === "yes" ? (
        <React.Fragment>
          {images ? (
            <ImageInput
              maxSize={2500}
              name={`crops[${index}].disease_images`}
              label={t(
                "crop.imagesForDiseaseSoilDeficiency",
                "Images for disease / soil deficiency",
              )}
              required={true}
              startingValue={crops[index].disease_images}
            />
          ) : null}
          <TextInput
            label={t(
              "crop.commentsOnDiseaseSoilDeficiency",
              "Comment on the disease / soil deficiency",
            )}
            name={`crops[${index}].comment`}
          />
        </React.Fragment>
      ) : null}
      {isYieldCrop(crops[index].crop, t) && (
        <YieldInput index={index} images={images} />
      )}
    </React.Fragment>
  );
}

export default withTranslation()(CropItem);
