import React, { useState, useRef, useEffect } from "react";
import { withTranslation } from "react-i18next";
import { Formik, Form, useFormikContext } from "formik";

import { AgGridReact } from "ag-grid-react";
import { Bar } from "react-chartjs-2";
import { MapContainer, TileLayer, useMap } from "react-leaflet";
import ReactLeafletGoogleLayer from "react-leaflet-google-layer";
import L from "leaflet";
import "leaflet.markercluster";

import LoadingModal from "../../common/components/LoadingModal.js";
import DateInput from "../../common/components/form/DateInput.js";
import Select from "../../common/components/form/Select.js";
import Image from "../../common/components/Image.js";

import { useUser } from "../../user/utils/user";
import { get } from "../../common/utils/api";
import {
  comparator,
  formatDateTime,
  getImageUrl,
  formatDate,
} from "../../common/utils/helpers";
import { getDatesBetweenDates } from "./SummarySupervisor.js";
import "leaflet/dist/leaflet.css";
import "leaflet.markercluster/dist/MarkerCluster.css";
import "leaflet.markercluster/dist/MarkerCluster.Default.css";

import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";

const API_KEY = "AIzaSyCEhcz4rL7-zCP2TUpDLlKIe63cq4Dwfuc";
export const REQUIRED_REVIEWS = 3;
function ChangeView({ center, zoom }) {
  const map = useMap();
  useEffect(() => {
    map.setView(center, zoom);
  }, [center, zoom]);
  return null;
}

function MarkerClusterGroup({ points }) {
  const map = useMap();
  useEffect(() => {
    var markers = L.markerClusterGroup({
      chunkedLoading: true,
      disableClusteringAtZoom: 14,
    });

    points.forEach((p) => {
      if (p.latitude && p.longitude) {
        var marker = L.marker(L.latLng(p.latitude, p.longitude), {
          title: p.text,
        });
        marker.bindPopup(p.text);
        markers.addLayer(marker);
      }
    });
    map.addLayer(markers);
    return () => {
      map.removeLayer(markers);
    };
  }, [points]);
  return null;
}

function QueryForm({ groups, t }) {
  const { initialValues, submitForm } = useFormikContext();
  // Automatically refresh form if input values change
  useEffect(() => {
    if (initialValues) {
      submitForm();
    }
  }, [initialValues, submitForm]);
  return (
    <React.Fragment>
      <div className="space-y-6 pt-8 sm:space-y-5 sm:pt-10">
        <div className="space-y-6 sm:space-y-5">
          <DateInput label={"Start date"} name={"formStartDate"} />
          <DateInput label={"End date"} name={"formEndDate"} />
          <Select label={"Group"} name={"formGroup"} options={groups} />
        </div>
        <div>
          <div className="flex justify-end">
            <button
              type="submit"
              className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-cyan-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2 disabled:bg-gray-500 disabled:cursor-not-allowed"
            >
              {t("common.generate", "Generate Report")}
            </button>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

function SuperuserView({ showAlert, t }) {
  const [groupOptions, setGroupOptions] = useState([]);
  const formRef = useRef();
  const [summaryData, setSummaryData] = useState({});
  // Default date range: past three days
  let today = new Date();
  let daysBefore = new Date();
  daysBefore.setDate(today.getDate() - 30);

  let [fullData, setFullData] = useState([]);
  let [rowData, setRowData] = useState([]);
  let [position, setPosition] = useState([6.0, -3.8]);

  const [url, setUrl] = useState("");
  const [open, setOpen] = useState(false);

  let [useSatellite, setUseSatellite] = useState(false);

  const { user } = useUser();

  const grid = useRef();
  const clearFilters = () => {
    grid.api.setFilterModel(null);
    grid.api.onFilterChanged();
  };
  const grid2 = useRef();
  const onGridReady2 = (params) => {
    grid2.api = params.api;
  };

  function exportData() {
    function getParams() {
      return {
        allColumns: true,
      };
    }
    grid.api.exportDataAsCsv(getParams());
  }

  const [columnDefs] = useState([
    { field: "id", headerName: "ID" },
    { field: "is_approved", headerName: "Approved" },
    { field: "is_reviewed", headerName: "Reviewed" },
    { field: "user", headerName: t("user.user", "User") },
    { field: "email", headerName: t("user.email", "Email") },
    { field: "groups", headerName: t("user.groups", "Groups") },
    {
      field: "form_created_at",
      headerName: t("common.created", "Created"),
      valueFormatter: formatDateTime,
      filter: "agDateColumnFilter",
      filterParams: { comparator },
    },
    {
      field: "form_updated_at",
      headerName: t("common.updated", "Updated"),
      valueFormatter: formatDateTime,
      filter: "agDateColumnFilter",
      filterParams: { comparator },
      hide: true,
    },
    {
      field: "created_at",
      headerName: t("common.submitted", "Submitted"),
      valueFormatter: formatDateTime,
      filter: "agDateColumnFilter",
      filterParams: { comparator },
    },
    { field: "name", headerName: t("plot.name", "Name") },
    { field: "crop1", headerName: t("crop.crop", "Crop") },
    {
      field: "otherCropType1",
      headerName: t("crop.otherCropType", "Other Crop Type"),
    },
    {
      field: "preparedCropType1",
      headerName: t("crop.preparedCropType", "Prepared Crop Type"),
    },
    { field: "phenology1", headerName: t("crop.phenology", "Phenology") },
    {
      field: "images1",
      cellRenderer: function (params) {
        if (params.value) {
          return params.value.join("; ");
        }
      },
    },
    {
      field: "disease1",
      headerName: t("crop.diseaseSoilDeficiency", "Disease / Soil Deficiency"),
    },
    {
      field: "disease_images1",
      hide: true,
    },
    {
      field: "yield_images1",
      hide: true,
    },
    {
      field: "comment1",
      hide: true,
    },
    {
      field: "crop2",
      hide: true,
    },
    {
      field: "phenology2",
      hide: true,
    },
    {
      field: "images2",
      hide: true,
    },
    {
      field: "disease2",
      hide: true,
    },
    {
      field: "disease_images2",
      hide: true,
    },
    {
      field: "yield_images2",
      hide: true,
    },
    {
      field: "comment2",
      hide: true,
    },
    {
      field: "crop3",
      hide: true,
    },
    {
      field: "phenology3",
      hide: true,
    },
    {
      field: "images3",
      hide: true,
    },
    {
      field: "disease3",
      hide: true,
    },
    {
      field: "disease_images3",
      hide: true,
    },
    {
      field: "yield_images3",
      hide: true,
    },
    {
      field: "comment3",
      hide: true,
    },
    { field: "latitude", headerName: t("plot.latitude", "Latitude") },
    { field: "longitude", headerName: t("plot.longitude", "Longitude") },
    { field: "accuracy", headerName: t("plot.accuracy", "Accuracy") },
    { field: "irrigation", headerName: t("plot.irrigation", "Irrigation") },
  ]);
  const onFilterChanged = (e) => {
    e.api.selectAllFiltered();
    setRowData(e.api.getSelectedRows());
    e.api.deselectAll();
  };

  function addCropItem(info, crops, i) {
    if (crops && crops.length > i) {
      info[`crop${i + 1}`] = crops[i].crop;
      info[`otherCropType${i + 1}`] = crops[i].otherCropType
        ? crops[i].otherCropType
        : "";
      info[`images${i + 1}`] = crops[i].images;
      info[`phenology${i + 1}`] = crops[i].phenology;
      info[`disease${i + 1}`] = crops[i].disease;
      info[`disease_images${i + 1}`] = crops[i].disease_images;
      info[`yield_images${i + 1}`] = crops[i].yield_images;
      info[`comment${i + 1}`] = crops[i].comment;
    }
  }
  const getSummaryData = async (start_date, end_date, group, setSubmitting) => {
    get(
      `/data/review/status/?start_date=${start_date}&end_date=${end_date}&group=${group}`,
    )
      .then((response) => {
        return response.data;
      })
      .then(function (data) {
        // Column definition
        let incompleteReviewSummaryColumns = [
          {
            field: "number_of_answers",
            headerName: t("Total answers", "# Total answers"),
          },
          {
            field: "remaining",
            headerName: t("Remaining", "# Remaining"),
          },
          {
            field: "stuck",
            headerName: t("stuck", "# Stuck"),
          },
        ];
        // labels for x-axis
        let labels = getDatesBetweenDates(start_date, end_date);
        // get data from api to show in chart for completed summary
        let reviewCompleteSummary = data.review_complete_summary;
        var dataByDayVals = [];
        reviewCompleteSummary.reduce(function (res, value) {
          if (!res[value.date]) {
            res[value.date] = 0;
            dataByDayVals = res;
          }
          res[value.date] += value.count;
          return res;
        }, {});
        const reviewCompleteData = {
          labels,
          datasets: [
            {
              label: "Dataset",
              data: labels.map((l) => dataByDayVals[l]),
              backgroundColor: "rgba(255, 99, 132, 0.5)",
            },
          ],
        };
        // data for the reviews which are incomplete
        let incompleteReviewData = data.review_incomplete_summary.map((d) => {
          // calculate the remaining reviews for each question answered
          let remainingCount =
            d.count *
            (d.number_of_answers !== REQUIRED_REVIEWS
              ? REQUIRED_REVIEWS - d.number_of_answers
              : 1);

          return {
            number_of_answers: d.number_of_answers,
            remaining: remainingCount,
            stuck: remainingCount - d.queued_number,
          };
        });
        setSummaryData({
          reviewCompleteData,
          incompleteReviewData,
          incompleteReviewSummaryColumns,
        });
      })
      .finally(() => setTimeout(() => setSubmitting(false), 300));
  };

  const getData = async (start_date, end_date, group, setSubmitting) => {
    get(
      `/data/superuser/?start_date=${start_date}&end_date=${end_date}&group=${group}`,
    )
      .then((response) => {
        return response.data.map((d) => {
          var info = {
            form_created_at: d.form_created_at || "",
            form_updated_at: d.data.form_updated_at || "",
            created_at: d.created_at || "",
            user: d.user__first_name + " " + d.user__last_name,
            email: d.user__email,
            groups: d.user__group_names,
            id: d.id,
            irrigation: d.data.irrigation,
            latitude: d.data?.latitude,
            longitude: d.data?.longitude,
            accuracy: d.data.manualLocation ? null : d.data.accuracy,
            name: d.data.name,
            // Approved if all reviews are approves
            is_approved: d.is_approved,
            is_reviewed: d.is_reviewed,
          };
          addCropItem(info, d.data.crops, 0);
          addCropItem(info, d.data.crops, 1);
          addCropItem(info, d.data.crops, 2);
          return info;
        });
      })
      .then(function (data) {
        setFullData(data);
        setRowData(data);
        if (data[0]) {
          setPosition([data[0].latitude, data[0].longitude]);
        }
      })
      .finally(() => setTimeout(() => setSubmitting(false), 300));
  };

  function onSubmit(value, { setSubmitting }) {
    getData(
      value.formStartDate,
      value.formEndDate,
      value.formGroup,
      setSubmitting,
    );

    getSummaryData(
      value.formStartDate,
      value.formEndDate,
      value.formGroup,
      setSubmitting,
    );
    sessionStorage.setItem("startDate", value.formStartDate);
    sessionStorage.setItem("endDate", value.formEndDate);
  }
  const initialValues = {
    formStartDate:
      sessionStorage.getItem("startDate") || formatDate(daysBefore),
    formEndDate: sessionStorage.getItem("endDate") || formatDate(today),
    formGroup: "All",
  };

  const getGroups = async () => {
    get("/users/groups").then((response) => {
      setGroupOptions(["All", ...response.data]);
    });
  };
  useEffect(() => {
    getGroups();
  }, []);

  useEffect(() => {
    delete L.Icon.Default.prototype._getIconUrl;

    L.Icon.Default.mergeOptions({
      iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
      iconUrl: require("leaflet/dist/images/marker-icon.png"),
      shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
    });
  }, []);

  const onGridReady = (params) => {
    grid.api = params.api;
    grid.api.showLoadingOverlay();

    grid.api.sizeColumnsToFit();
  };
  const autoSizeColumns = (params) => {
    const colIds = params.columnApi
      .getAllDisplayedColumns()
      .map((col) => col.getColId());

    params.columnApi.autoSizeColumns(colIds);
  };

  function clickEvent(e) {
    if (e.colDef.field.includes("images")) {
      setUrl(getImageUrl(e.value[0]));
      setOpen(true);
    }
  }
  return user ? (
    <React.Fragment>
      <Image url={url} open={open} setOpen={setOpen} />
      <div className="mx-auto max-w-2xl px-4 sm:px-6 bg-white shadow rounded-md py-4 sm:py-6">
        <div>
          <h3 className="text-lg font-medium leading-6 text-gray-900">
            {t("common.summary", "Retrieve Data")}
          </h3>
        </div>
        <Formik
          enableReinitialize
          initialValues={initialValues}
          onSubmit={onSubmit}
          innerRef={formRef}
        >
          {({ isSubmitting }) => (
            <React.Fragment>
              <LoadingModal open={isSubmitting} />
              <Form className="space-y-8 divide-y divide-gray-200">
                <QueryForm groups={groupOptions} t={t} />
              </Form>
            </React.Fragment>
          )}
        </Formik>
      </div>
      {summaryData.reviewCompleteData ? (
        <div className="mt-4">
          <div className="sm:flex-auto bg-white p-4 shadow rounded-md">
            <Bar
              options={{
                responsive: true,
                plugins: {
                  legend: {
                    display: false,
                  },
                  title: {
                    display: true,
                    text: "Reviews complete",
                  },
                },
              }}
              data={summaryData.reviewCompleteData}
              height={80}
            />
          </div>
          <div className="sm:flex-auto mt-4">
            <p className="text-lg font-bold text-center text-gray-900 tracking-tight">
              {t(
                "common.numberOfRecordsForEachUserByDate",
                "Incomplete review summary",
              )}
            </p>
            <div className="mt-2 ag-theme-alpine" style={{ height: 250 }}>
              <AgGridReact
                ref={grid2}
                rowData={summaryData.incompleteReviewData}
                columnDefs={summaryData.incompleteReviewSummaryColumns}
                defaultColDef={{
                  sortable: true,
                  resizable: true,
                }}
                onGridReady={onGridReady2}
              />
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
      <div className="mt-4 mb-2 flex justify-end">
        <button
          onClick={clearFilters}
          className={
            "rounded-md border border-gray-300 py-2 px-4 text-sm font-medium text-gray-700 shadow-sm focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2 bg-white hover:bg-gray-50"
          }
        >
          {t("common.clearFilters", "Clear filters")}
        </button>
        <button
          onClick={exportData}
          className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-cyan-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2 disabled:bg-gray-500 disabled:cursor-not-allowed"
        >
          {t("common.exportToCSV", "Export to CSV")}
        </button>
      </div>
      <div className="ag-theme-alpine" style={{ height: 400 }}>
        <AgGridReact
          ref={grid}
          rowData={fullData}
          columnDefs={columnDefs}
          defaultColDef={{
            sortable: true,
            filter: true,
            resizable: true,
          }}
          overlayLoadingTemplate={
            '<span class="ag-overlay-loading-center">Please wait while your rows are loading</span>'
          }
          onCellClicked={clickEvent}
          onFilterChanged={onFilterChanged}
          onFirstDataRendered={autoSizeColumns}
          onGridReady={onGridReady}
        ></AgGridReact>
      </div>
      <MapContainer
        className="mt-4"
        center={position}
        style={{ height: 400, position: "relative" }}
        maxZoom={18}
        zoom={7}
      >
        <ChangeView center={position} zoom={7} />
        <MarkerClusterGroup
          points={rowData.map((r) => {
            return {
              latitude: r.latitude,
              longitude: r.longitude,
              text: r.id + ": " + r.crop1,
            };
          })}
        />
        <div
          className="m-2 justify-end"
          style={{
            position: "absolute",
            top: 0,
            right: 0,
            zIndex: 9,
          }}
        >
          <button
            onClick={() => setUseSatellite(!useSatellite)}
            className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-cyan-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2 disabled:bg-gray-500 disabled:cursor-not-allowed"
          >
            {useSatellite
              ? t("common.changeToStreet", "Change to Street")
              : t("common.changeToSatellite", "Change to Satellite")}
          </button>
        </div>
        {useSatellite ? (
          <ReactLeafletGoogleLayer apiKey={API_KEY} type={"satellite"} />
        ) : (
          <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
        )}
      </MapContainer>
    </React.Fragment>
  ) : null;
}
export default withTranslation()(SuperuserView);
