import { Form, Formik, useFormikContext } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { withTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { AgGridReact } from "ag-grid-react";

import { get } from "../../common/utils/api";
import {
  calculatePercentage,
  formatDate,
  getStandardizedQuestionCode,
} from "../../common/utils/helpers";

import LoadingModal from "../../common/components/LoadingModal.js";
import DateInput from "../../common/components/form/DateInput.js";
import Select from "../../common/components/form/Select.js";

import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { useUser } from "../../user/utils/user";
import { questionCodeMapper } from "./ReviewDetail";

function SummaryForm({ groups, t }) {
  const { initialValues, submitForm } = useFormikContext();
  const { user } = useUser();
  // Automatically refresh form if input values change
  useEffect(() => {
    if (initialValues) {
      submitForm();
    }
  }, [initialValues, submitForm]);
  return (
    <React.Fragment>
      <div className="space-y-6 pt-8 sm:space-y-5 sm:pt-10">
        <div className="space-y-6 sm:space-y-5">
          <DateInput label={"Start date"} name={"formStartDate"} />
          <DateInput label={"End date"} name={"formEndDate"} />
          {user.user.is_supervisor && (
            <Select label={"Group"} name={"formGroup"} options={groups} />
          )}
        </div>
        <div>
          <div className="flex justify-end">
            <button
              type="submit"
              className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-cyan-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2 disabled:bg-gray-500 disabled:cursor-not-allowed"
            >
              {t("common.generate", "Generate Report")}
            </button>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

const groupByCrop = (data) => {
  const groupedData = data.reduce((acc, item) => {
    const { username, crop, id } = item;

    if (!acc[username]) {
      acc[username] = {
        username,
        crops: {},
      };
    }

    // Track unique ids to avoid duplicate counting
    const uniqueIds = new Set(acc[username].uniqueIds || []);
    if (uniqueIds.has(id)) {
      return acc;
    }
    uniqueIds.add(id);
    acc[username].uniqueIds = uniqueIds;

    // Aggregate crops
    if (!acc[username].crops[crop]) {
      acc[username].crops[crop] = 1;
    }
    acc[username].crops[crop]++;

    return acc;
  }, {});

  let user_data = Object.values(groupedData).map((item) => ({
    username: item.username,
    ...item.crops,
  }));

  // Sort user_data by username
  return user_data.sort((a, b) => a.username.localeCompare(b.username));
};

const groupByUsername = (data) => {
  const groupedData = data.reduce((acc, item) => {
    const key = item.username;
    if (!acc[key]) {
      acc[key] = {
        username: item.username,
        entries: [{ id: item.id }],
      };
    }
    // Find the index of the existing entry for this key
    const entryIndex = acc[key].entries.findIndex(
      (entry) => entry.id === item.id,
    );
    if (entryIndex !== -1) {
      // Update the existing entry
      acc[key].entries[entryIndex] = {
        ...acc[key].entries[entryIndex],
        question_code: [
          ...(acc[key].entries[entryIndex].question_code || []),
          item.question_code,
        ],
        review_complete: [
          ...(acc[key].entries[entryIndex].review_complete || []),
          item.review_complete,
        ],
        is_approved: [
          ...(acc[key].entries[entryIndex].is_approved || []),
          item.is_approved,
        ],
      };
    } else {
      // Add a new entry
      acc[key].entries.push({
        id: item.id,
        question_code: [item.question_code],
        review_complete: [item.review_complete],
        is_approved: [item.is_approved],
      });
    }

    return acc;
  }, {});

  let user_data = Object.values(groupedData).map((item) => ({
    ...item,
    parcel_count: item.entries.length,
    reviewed_count: item.entries.filter((entry) =>
      entry.review_complete.every((status) => status === true),
    ).length,
    approved_count: item.entries.filter((entry) =>
      entry.is_approved.every((status) => status === true),
    ).length,
  }));

  user_data.forEach((item) => {
    item.approved_percent = calculatePercentage(
      item.approved_count,
      item.reviewed_count,
    );
  });
  // Sort user_data by username
  return user_data.sort((a, b) => a.username.localeCompare(b.username));
};

const groupByUsernameAndDate = (data) => {
  const groupedData = data.reduce((acc, item) => {
    const key = `${item.username}-${item.form_created_date}`;

    // Initialize the entry for this key if it doesn't exist yet
    if (!acc[key]) {
      acc[key] = {
        username: item.username,
        date: item.form_created_date,
        entries: [{ id: item.id }],
      };
    }

    // Find the index of the existing entry for this key
    const entryIndex = acc[key].entries.findIndex(
      (entry) => entry.id === item.id,
    );

    if (entryIndex !== -1) {
      // Update the existing entry
      acc[key].entries[entryIndex] = {
        ...acc[key].entries[entryIndex],
        question_code: [
          ...(acc[key].entries[entryIndex].question_code || []),
          item.question_code,
        ],
        review_complete: [
          ...(acc[key].entries[entryIndex].review_complete || []),
          item.review_complete,
        ],
        is_approved: [
          ...(acc[key].entries[entryIndex].is_approved || []),
          item.is_approved,
        ],
      };
    } else {
      // Add a new entry
      acc[key].entries.push({
        id: item.id,
        question_code: [item.question_code],
        review_complete: [item.review_complete],
        is_approved: [item.is_approved],
      });
    }

    return acc;
  }, {});

  let user_data = Object.values(groupedData).map((item) => ({
    ...item,
    parcel_count: item.entries.length,
    reviewed_count: item.entries.filter((entry) =>
      entry.review_complete.every((status) => status === true),
    ).length,
    approved_count: item.entries.filter((entry) =>
      entry.is_approved.every((status) => status === true),
    ).length,
  }));

  user_data.forEach((item) => {
    item.approved_percent = calculatePercentage(
      item.approved_count,
      item.reviewed_count,
    );
  });

  // Sort user_data by username and date
  return user_data.sort((a, b) => {
    if (a.username === b.username) {
      return new Date(a.date) - new Date(b.date);
    }
    return a.username.localeCompare(b.username);
  });
};

const groupByUsernameAndQuestionCode = (data) => {
  let groupedData = data.reduce((acc, item) => {
    const key = `${item.username}-${getStandardizedQuestionCode(
      item.question_code,
    )}`;
    if (!acc[key]) {
      const code = getStandardizedQuestionCode(item.question_code);
      acc[key] = {
        username: item.username,
        question_code: code,
        question_code_decoded: questionCodeMapper[code],
        reviewed_count: item.review_complete === true ? 1 : 0,
        rejected_count: item.is_approved === false ? 1 : 0,
      };
    } else {
      acc[key].reviewed_count += item.review_complete === true ? 1 : 0;
      acc[key].rejected_count += item.is_approved === false ? 1 : 0;
    }
    return acc;
  }, {});

  let user_data = Object.values(groupedData);

  user_data.forEach((item) => {
    item.rejected_percent = calculatePercentage(
      item.rejected_count,
      item.reviewed_count,
    );
  });

  // Sort user_data by username and question_code
  return user_data.sort((a, b) => {
    if (a.username === b.username) {
      return a.question_code - b.question_code;
    }
    return a.username.localeCompare(b.username);
  });
};

function Summary({ t }) {
  const [groupOptions, setGroupOptions] = useState([]);
  const [summaryData, setSummaryData] = useState({});
  const [cropColumnDefs, setCropColumnDefs] = useState([]);

  const navigate = useNavigate();
  const formRef = useRef();

  // Default date range: past three days
  let today = new Date();
  let daysBefore = new Date();
  daysBefore.setDate(today.getDate() - 30);

  let userDetailsColumnDefsByUsername = [
    { field: "username", headerName: t("user.email", "Username") },
    {
      field: "parcel_count",
      headerName: t("common.reviewed", "# Parcels"),
      type: "numericColumn",
    },
    {
      field: "reviewed_count",
      headerName: t("common.reviewed", "# Reviewed"),
      type: "numericColumn",
    },
    {
      field: "approved_count",
      headerName: t("common.approved", "# Approved"),
      type: "numericColumn",
    },
    {
      field: "approved_percent",
      headerName: t("common.approved", "% Approved"),
      type: "numericColumn",
      valueFormatter: (params) => params.data.approved_percent.toFixed(2),
    },
  ];

  let userDetailsColumnDefsByDate = [
    { field: "username", headerName: t("user.email", "Username") },
    {
      field: "date",
      headerName: t("common.total", "Date"),
      type: "numericColumn",
    },
    {
      field: "parcel_count",
      headerName: t("common.reviewed", "# Parcels"),
      type: "numericColumn",
    },
    {
      field: "reviewed_count",
      headerName: t("common.reviewed", "# Reviewed"),
      type: "numericColumn",
      cellRenderer: (params) => {
        return params.data.reviewed_count === 0 ? (
          <span />
        ) : (
          <span className="text-cyan-600 hover:text-cyan-900 cursor-pointer">
            {params.value}
          </span>
        );
      },
      onCellClicked(params) {
        if (params.data.reviewed_count > 0) {
          navigate(
            `/data/review/parcel?user=${params.data.username}&date=${params.data.date}`,
          );
        }
      },
      cellClass: ["text-right", "cursor-pointer"],
    },
    {
      field: "approved_count",
      headerName: t("common.approved", "# Approved"),
      type: "numericColumn",
    },
    {
      field: "approved_percent",
      headerName: t("common.approved", "% Approved"),
      type: "numericColumn",
      valueFormatter: (params) => params.data.approved_percent.toFixed(2),
    },
  ];

  let userDetailsColumnDefsByQuestions = [
    { field: "username", headerName: t("user.email", "Username") },
    {
      field: "question_code",
      headerName: t("common.Question", "Question"),
      type: "numericColumn",
      tooltipField: "question_code_decoded",
      cellClass: ["text-right", "cursor-pointer"],
    },
    {
      field: "rejected_count",
      headerName: t("common.rejected", "# Rejected"),
      type: "numericColumn",
    },
    {
      field: "rejected_percent",
      headerName: t("common.rejected", "% Rejected"),
      type: "numericColumn",
      valueFormatter: (params) => params.data.rejected_percent.toFixed(2),
    },
  ];

  const generateColumnDefs = (data) => {
    const uniqueCrops = new Set();

    data.forEach((item) => {
      uniqueCrops.add(item.crop);
    });

    const columnDefs = [{ field: "username", headerName: "Username" }];

    uniqueCrops.forEach((crop) => {
      columnDefs.push({
        field: crop,
        headerName: `# ${crop}`,
        type: "numericColumn",

        valueGetter: (params) => params.data[crop] || 0,
      });
    });

    return columnDefs;
  };
  const getSummaryData = async (start_date, end_date, group, setSubmitting) => {
    get(
      `/data/summary/?start_date=${start_date}&end_date=${end_date}&group=${group}&username=All`,
    )
      .then((response) => {
        return response.data;
      })
      .then((data) => {
        setSummaryData({
          userDetailsGroupedByCrop: groupByCrop(data),
          userDetailsGroupedByUsername: groupByUsername(data),
          userDetailsGroupedByDate: groupByUsernameAndDate(data),
          userDetailsGroupedByQuestions: groupByUsernameAndQuestionCode(data),
        });
        const cropColumnDefsDynamic = generateColumnDefs(data);
        setCropColumnDefs(cropColumnDefsDynamic);
      })
      .finally(() => setTimeout(() => setSubmitting(false), 300));
  };

  const gridUsername = useRef(null);
  const gridDate = useRef(null);
  const gridQuestions = useRef(null);
  const gridCrop = useRef(null);

  const onGridReady = (params, grid) => {
    grid.current = params.api;
    params.api.sizeColumnsToFit();
  };

  const exportData = (grid) => {
    grid.current.exportDataAsCsv({
      allColumns: true,
      processCellCallback: (v) =>
        v.column.colDef.type === "combinedNumericColumn" ? v.value[0] : v.value,
    });
  };

  function onSubmit(value, { setSubmitting }) {
    getSummaryData(
      value.formStartDate,
      value.formEndDate,
      value.formGroup,
      setSubmitting,
    );
    sessionStorage.setItem("startDate", value.formStartDate);
    sessionStorage.setItem("endDate", value.formEndDate);
    sessionStorage.setItem("group", value.formGroup);
  }
  const initialValues = {
    formStartDate:
      sessionStorage.getItem("startDate") || formatDate(daysBefore),
    formEndDate: sessionStorage.getItem("endDate") || formatDate(today),
    formGroup: sessionStorage.getItem("group") || "All",
  };

  const getGroups = async () => {
    get("/users/groups").then((response) => {
      setGroupOptions(["All", ...response.data]);
    });
  };
  useEffect(() => {
    getGroups();
  }, []);

  return (
    <React.Fragment>
      <div className="mx-auto max-w-2xl px-4 sm:px-6 bg-white shadow rounded-md py-4 sm:py-6">
        <div>
          <h3 className="text-lg font-medium leading-6 text-gray-900">
            {t("common.summary", "Generate Summary Table")}
          </h3>
        </div>
        <Formik
          enableReinitialize
          initialValues={initialValues}
          onSubmit={onSubmit}
          innerRef={formRef}
        >
          {({ isSubmitting }) => (
            <React.Fragment>
              <LoadingModal open={isSubmitting} />
              <Form className="space-y-8 divide-y divide-gray-200">
                <SummaryForm groups={groupOptions} t={t} />
              </Form>
            </React.Fragment>
          )}
        </Formik>
      </div>
      <div className="mt-16">
        <div className="space-y-8">
          <div className="sm:flex-auto">
            <p className="text-lg font-bold text-center text-gray-900 tracking-tight">
              {t("common.numberOfRecordsByUser", "Overall User Activity")}
            </p>
            <div data-testid="summaryByUsername">
              <div className="mb-2 flex justify-end">
                <button
                  onClick={() => exportData(gridUsername)}
                  className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-cyan-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2 disabled:bg-gray-500 disabled:cursor-not-allowed"
                >
                  {t("common.exportToCSV", "Export to CSV")}
                </button>
              </div>
              <div className="mt-2 ag-theme-alpine" style={{ height: 400 }}>
                <AgGridReact
                  ref={gridUsername}
                  rowData={summaryData.userDetailsGroupedByUsername}
                  // columnTypes={{ combinedNumericColumn: {} }}
                  columnDefs={userDetailsColumnDefsByUsername}
                  defaultColDef={{
                    sortable: true,
                    resizable: true,
                  }}
                  onGridReady={(params) => onGridReady(params, gridUsername)}
                />
              </div>
            </div>
          </div>
          <div className="sm:flex-auto">
            <p className="text-lg font-bold text-center text-gray-900 tracking-tight">
              {t(
                "common.numberOfRecordsForEachUserByDate",
                "Daily User Activity",
              )}
            </p>
            <div data-testid="summaryByDate">
              <div className="mb-2 flex justify-end">
                <button
                  onClick={() => exportData(gridDate)}
                  className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-cyan-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2 disabled:bg-gray-500 disabled:cursor-not-allowed"
                >
                  {t("common.exportToCSV", "Export to CSV")}
                </button>
              </div>
              <div className="mt-2 ag-theme-alpine" style={{ height: 400 }}>
                <AgGridReact
                  ref={gridDate}
                  rowData={summaryData.userDetailsGroupedByDate}
                  // columnTypes={{ combinedNumericColumn: {} }}
                  columnDefs={userDetailsColumnDefsByDate}
                  defaultColDef={{
                    sortable: true,
                    resizable: true,
                  }}
                  onGridReady={(params) => onGridReady(params, gridDate)}
                />
              </div>
            </div>
          </div>
          <div className="sm:flex-auto">
            <p className="text-lg font-bold text-center text-gray-900 tracking-tight">
              {t(
                "common.numberOfRecordsByUserByQuestion",
                "Most Common Mistakes",
              )}
            </p>
            <div data-testid="summaryByQuestions">
              <div className="mb-2 flex justify-end">
                <button
                  onClick={() => exportData(gridQuestions)}
                  className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-cyan-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2 disabled:bg-gray-500 disabled:cursor-not-allowed"
                >
                  {t("common.exportToCSV", "Export to CSV")}
                </button>
              </div>
              <div className="mt-2 ag-theme-alpine" style={{ height: 400 }}>
                <AgGridReact
                  ref={gridQuestions}
                  rowData={summaryData.userDetailsGroupedByQuestions}
                  // columnTypes={{ combinedNumericColumn: {} }}
                  columnDefs={userDetailsColumnDefsByQuestions}
                  defaultColDef={{
                    sortable: true,
                    resizable: true,
                  }}
                  onGridReady={(params) => onGridReady(params, gridQuestions)}
                />
              </div>
            </div>
          </div>
          <div className="sm:flex-auto">
            <p className="text-lg font-bold text-center text-gray-900 tracking-tight">
              {t("common.numberOfRecordsByUserByCrop", "Breakdown by Crop")}
            </p>
            <div data-testid="summaryByCrop">
              <div className="mb-2 flex justify-end">
                <button
                  onClick={() => exportData(gridCrop)}
                  className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-cyan-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2 disabled:bg-gray-500 disabled:cursor-not-allowed"
                >
                  {t("common.exportToCSV", "Export to CSV")}
                </button>
              </div>
              <div className="mt-2 ag-theme-alpine" style={{ height: 400 }}>
                <AgGridReact
                  ref={gridCrop}
                  rowData={summaryData.userDetailsGroupedByCrop}
                  columnDefs={cropColumnDefs}
                  defaultColDef={{
                    sortable: true,
                    resizable: true,
                  }}
                  onGridReady={(params) => onGridReady(params, gridCrop)}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
export default withTranslation()(Summary);
