import React, { useState } from "react";
import { Formik, Form } from "formik";
import { useNavigate, Link } from "react-router-dom";
import { useSearchParams } from "react-router-dom";
import { withTranslation } from "react-i18next";

import { post, unwrapError } from "../../common/utils/api";

import PasswordInput from "../components/PasswordInput.js";

function ResetPasswordConfirm({ showAlert, t }) {
  const navigate = useNavigate();
  const [errorData, setErrorData] = useState({});

  const [searchParams] = useSearchParams();

  function onSubmit(value, { setSubmitting }) {
    setErrorData({});
    if (value.password !== value.password2) {
      setErrorData({
        non_field_errors: "Password and Confirm Password are not equal",
      });
      setSubmitting(false);
    } else {
      post("/users/password_reset/confirm/", {
        password: value.password,
        token: searchParams.get("token"),
      })
        .then(function () {
          setErrorData({});
          showAlert({
            type: "success",
            message: "Password successfully reset",
          });
          navigate("/users/login");
        })
        .catch(function (error) {
          setErrorData(unwrapError(error, true));
        })
        .finally(() => setTimeout(() => setSubmitting(false), 300));
    }
  }

  return (
    <div>
      <div className="mt-12 text-center text-2xl font-bold tracking-tight text-gray-900 lg:text-4xl">
        {t("user.setNewPassword", "Set new password")}
      </div>
      <Formik
        initialValues={{ password: "", password2: "" }}
        onSubmit={onSubmit}
      >
        {({ isSubmitting }) => (
          <Form className="mt-6 mx-auto w-full max-w-sm">
            <p className="mb-6 text-center text-xs font-medium text-red-600">
              {errorData.non_field_errors || "\u00A0"}
            </p>
            <div className="mb-2">
              <PasswordInput errorData={errorData} isConfirm={false} />
            </div>
            <div className="mb-2">
              <PasswordInput errorData={errorData} isConfirm={true} />
            </div>
            <button
              type="submit"
              disabled={isSubmitting}
              className="inline-flex justify-center rounded-lg text-sm font-semibold py-2.5 px-4 bg-cyan-700 text-white hover:bg-cyan-600 w-full disabled:bg-gray-500 disabled:cursor-not-allowed"
            >
              <span>{t("user.resetPassword", "Reset password")}</span>
            </button>

            <Link
              to="/"
              className={`mt-1 inline-flex justify-center rounded-lg text-sm font-semibold py-2.5 px-4 border border-gray-300 text-gray-700 w-full  ${
                isSubmitting
                  ? "bg-gray-100 cursor-not-allowed"
                  : "bg-white hover:bg-gray-50"
              }`}
            >
              Cancel
            </Link>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default withTranslation()(ResetPasswordConfirm);
