import React, { useState, useEffect, useRef } from "react";
import { withTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Formik, Form, useFormikContext } from "formik";

import { get } from "../../common/utils/api";

import { AgGridReact } from "ag-grid-react";

import Select from "../../common/components/form/Select.js";
import LoadingModal from "../../common/components/LoadingModal.js";
import { formatDate } from "../../common/utils/helpers";

import { useUser } from "../../user/utils/user";

import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";

function ReviewForm({ groupOptions, t }) {
  const { initialValues, submitForm } = useFormikContext();
  // Automatically refresh form if input values change
  useEffect(() => {
    if (initialValues) {
      submitForm();
    }
  }, [initialValues, submitForm]);
  return (
    <React.Fragment>
      <div className="space-y-6 pt-8 sm:space-y-5 sm:pt-10">
        <div className="space-y-6 sm:space-y-5">
          <Select
            label={"Select Group"}
            name={"formGroup"}
            options={groupOptions}
          />
        </div>
      </div>
      <div className="py-5">
        <div className="flex justify-end">
          <button
            type="submit"
            className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-cyan-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2 disabled:bg-gray-500 disabled:cursor-not-allowed"
          >
            {t("common.viewAssignedReviews", "View assigned reviews")}
          </button>
        </div>
      </div>
    </React.Fragment>
  );
}

function ReviewQueue({ showAlert, t }) {
  let [summaryData, setSummaryData] = useState([]);
  const [groupOptions, setGroupOptions] = useState([]);
  const navigate = useNavigate();
  const { user } = useUser();

  // Use session storage for the existing user and dates if they exist
  const initialValues = {
    formGroup: sessionStorage.getItem("group") || "All",
  };

  let summaryColumnDefs = [
    {
      field: "review",
      headerName: "",
      width: 80,
      cellRenderer: () => t("plot.review", "review"),
      onCellClicked: function (params) {
        navigate(
          `/data/review/detail?user=${params.data.user}&date=${params.data.date}`,
        );
      },
      cellClass: ["text-cyan-600", "hover:text-cyan-900", "cursor-pointer"],
    },
    { field: "user", headerName: t("user.user", "User") },
    { field: "count", headerName: t("user.numberRemaining", "# Remaining") },
    {
      field: "date",
      headerName: t("common.createddate", "Date"),
      width: 120,
    },
  ];

  const getGroups = async () => {
    get("/users/groups").then((response) => {
      setGroupOptions(["All", ...response.data]);
    });
  };
  const getData = async (group_selected, setSubmitting) => {
    get(`/data/review/queue?group=${group_selected}`)
      .then((response) => response.data)
      .then((data) => {
        // Group the data by date and user
        const groupedData = {};
        data.forEach((item) => {
          const key = `${item.date}-${item.user}`;
          if (!groupedData[key]) {
            groupedData[key] = { date: item.date, user: item.user, count: 0 };
          }
          groupedData[key].count++;
        });

        const summaryData = Object.values(groupedData);
        // Sort the summaryData array by date in descending order
        // summaryData.sort((a, b) => new Date(b.date) - new Date(a.date));
        setSummaryData(summaryData);
      })
      .finally(() => setTimeout(() => setSubmitting(false), 300));
  };

  const grid = useRef();
  const onGridReady = (params) => {
    grid.api = params.api;
    grid.api.sizeColumnsToFit();
  };

  useEffect(() => {
    getGroups();
  }, []);

  function onSubmit(value, { setSubmitting }) {
    getData(value.formGroup, setSubmitting);
    sessionStorage.setItem("group", value.formGroup);
  }

  return (
    <React.Fragment>
      <div className="mx-auto max-w-2xl px-4 sm:px-6 lg:px-8">
        <div className="mx-auto max-w-2xl px-4 sm:px-6 bg-white border-gray-200 shadow rounded-md pt-6">
          <div>
            <h3 className="text-lg font-medium leading-6 text-gray-900">
              {t("common.summary", "Generate Review Queue")}
            </h3>
          </div>
          <Formik
            enableReinitialize
            initialValues={initialValues}
            onSubmit={onSubmit}
          >
            {({ isSubmitting }) => (
              <React.Fragment>
                <LoadingModal open={isSubmitting} />
                <Form className="space-y-8 divide-y divide-gray-200">
                  <ReviewForm user={user} groupOptions={groupOptions} t={t} />
                </Form>
              </React.Fragment>
            )}
          </Formik>
        </div>
      </div>
      <div className="mt-8 flex flex-col">
        <div className="mx-auto max-w-2xl px-4 sm:px-6 lg:px-8">
          <div>
            <h3 className="text-lg font-medium leading-6 text-gray-900">
              {t("common.reviewSummary", "Review queue")}
            </h3>
          </div>
        </div>
        <div className="ag-theme-alpine mt-4" style={{ height: 400 }}>
          <AgGridReact
            ref={grid}
            rowData={summaryData}
            columnDefs={summaryColumnDefs}
            defaultColDef={{
              sortable: true,
              resizable: true,
            }}
            onGridReady={onGridReady}
          ></AgGridReact>
        </div>
      </div>
    </React.Fragment>
  );
}
export default withTranslation()(ReviewQueue);
