import React, { useEffect, useRef } from "react";
import { withTranslation } from "react-i18next";
import { Formik, Form, useFormikContext } from "formik";

import { get } from "../../common/utils/api";
import { formatDate, downloadFile } from "../../common/utils/helpers";

import DateInput from "../../common/components/form/DateInput.js";
import LoadingModal from "../../common/components/LoadingModal.js";

function ReviewForm({ userOptions, t }) {
  const { initialValues, submitForm } = useFormikContext();
  // Automatically refresh form if input values change
  useEffect(() => {
    if (initialValues) {
      submitForm();
    }
  }, [initialValues, submitForm]);
  return (
    <React.Fragment>
      <div className="space-y-6 pt-8 sm:space-y-5 sm:pt-10">
        <div className="space-y-6 sm:space-y-5">
          <DateInput label={"Start date"} name={"formStartDate"} />
          <DateInput label={"End date"} name={"formEndDate"} />
        </div>
      </div>
      <div className="py-5">
        <div className="flex justify-end">
          <button
            type="submit"
            className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-cyan-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2 disabled:bg-gray-500 disabled:cursor-not-allowed"
          >
            {t("common.generate", "Generate Report")}
          </button>
        </div>
      </div>
    </React.Fragment>
  );
}

function Landcover2ReviewSummary({ showAlert, t }) {
  const formRef = useRef();

  // Default date range: past three days for now
  let today = new Date();
  let daysBefore = new Date();
  daysBefore.setDate(today.getDate() - 3);

  // Use session storage for the existing user and dates if they exist
  const initialValues = {
    formStartDate:
      sessionStorage.getItem("startDate") || formatDate(daysBefore),
    formEndDate: sessionStorage.getItem("endDate") || formatDate(today),
  };

  const exportToGeoJson = (e) => {
    e.preventDefault();
    const start_date = formRef.current.values.formStartDate;
    const end_date = formRef.current.values.formEndDate;

    // Format the start_date & end_date to 00:00 & 23:59 in local time
    const start_time = new Date(start_date + " 00:00:00.0").toISOString();
    const end_time = new Date(end_date + " 23:59:59.999").toISOString();
    get(
      `/landcover2/download/?start_date=${start_time}&end_date=${end_time}`,
    ).then((response) => {
      downloadFile({
        data: JSON.stringify(response.data),
        fileName: "download.geojson",
        fileType: "application/geo+json",
      });
    });
  };

  return (
    <React.Fragment>
      <div className="mx-auto max-w-2xl px-4 sm:px-6 lg:px-8">
        <div className="mx-auto max-w-2xl px-4 sm:px-6 bg-white border-gray-200 shadow rounded-md pt-6">
          <div>
            <h3 className="text-lg font-medium leading-6 text-gray-900">
              {t("common.summary", "Generate Review Summary")}
            </h3>
          </div>
          <Formik
            enableReinitialize
            initialValues={initialValues}
            onSubmit={() => {}}
            innerRef={formRef}
          >
            {({ isSubmitting }) => (
              <React.Fragment>
                <Form className="space-y-8 divide-y divide-gray-200">
                  <ReviewForm t={t} />
                </Form>
              </React.Fragment>
            )}
          </Formik>
        </div>
      </div>
      <div className="mt-8 flex flex-col">
        <div className="mx-auto max-w-2xl px-4 sm:px-6 lg:px-8">
          <div>
            <h3 className="text-lg font-medium leading-6 text-gray-900">
              {t("common.reviewSummary", "Review Summary")}
            </h3>
          </div>
        </div>
        <div className="flex justify-end">
          <button
            type="submit"
            onClick={exportToGeoJson}
            className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-cyan-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2 disabled:bg-gray-500 disabled:cursor-not-allowed"
          >
            {t("common.downloadData", "Download data as GeoJSON")}
          </button>
        </div>
      </div>
    </React.Fragment>
  );
}
export default withTranslation()(Landcover2ReviewSummary);
