import React, { useState, useEffect, useRef, useMemo } from "react";
import { withTranslation } from "react-i18next";
import MapboxDraw from "@mapbox/mapbox-gl-draw";

import "mapbox-gl/dist/mapbox-gl.css";
import "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css";

import { get, post, unwrapError } from "../../common/utils/api";
import { createMap, showTask } from "../utils/map";
import LoadingModal from "../../common/components/LoadingModal";

function DelineationTask({ showAlert, t }) {
  const mapContainer = useRef(null);
  const map = useRef(null);

  const [task, setTask] = useState(null);
  const [mapLoaded, setMapLoaded] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);

  const draw = useMemo(() => {
    return new MapboxDraw({
      displayControlsDefault: false,
      // Select which mapbox-gl-draw control buttons to add to the map.
      controls: {
        polygon: true,
        trash: true,
      },
      // Set mapbox-gl-draw to draw by default.
      // The user does not have to click the polygon control button first.
      defaultMode: "draw_polygon",
    });
  }, []);

  useEffect(() => {
    if (mapContainer.current === null) return; // need map container to be showing
    if (map.current) return; // initialize map only once
    map.current = createMap(mapContainer.current, task);

    map.current.on("load", () => {
      map.current.on("draw.create", () => {
        // Setting the mode back to draw immediately causes the current shape
        // not to finish, so wait a bit
        setTimeout(() => draw.changeMode("draw_polygon"), 100);
      });

      map.current.addControl(draw);

      setMapLoaded(true);
    });
  });

  async function submit() {
    const parcels = [];
    draw.getAll().features.forEach((f) => {
      // There seems to be a phantom geometry last in the feature collection
      // that is 1-2 coordinates in the same place, ignore this one
      if (f.geometry.coordinates[0].length > 2) {
        parcels.push({ data: f });
      }
    });

    const payload = {
      longitude: task.longitude,
      latitude: task.latitude,
      width: task.width,
      date: task.date,
      parcel2_set: parcels,
    };
    return post("/parcels2/delineation_task/submit/", payload)
      .then(() => {
        draw.deleteAll();
        draw.changeMode("draw_polygon");
        showAlert({
          type: "success",
          message: t("common.successfullySubmitted", "Successfully submitted"),
        });
      })
      .catch(function (error) {
        showAlert({ type: "error", message: unwrapError(error) });
      });
  }

  const getTask = async () => {
    return get(`/parcels2/delineation_task/?group=Senegal`).then((response) => {
      if (response.data) {
        document.getElementById("map").style.display = "block";
        return response.data;
      } else {
        document.getElementById("map").style.display = "none";
        return null;
      }
    });
  };

  const next = async (skip) => {
    setIsUpdating(true);
    if (task && !skip) {
      await submit();
    }

    const t = await getTask();
    setTask(t);
    setIsUpdating(false);
  };

  function refreshTask() {
    if (!mapLoaded) return;
    if (!task) return;

    showTask(map.current, task);

    // Draw the central point
    const newData = {
      type: "FeatureCollection",
      features: [
        {
          type: "Feature",
          geometry: {
            type: "Point",
            coordinates: [task.longitude, task.latitude],
          },
        },
      ],
    };
    const geojsonSource = map.current.getSource("crops");
    if (geojsonSource !== undefined) {
      // Update the data after the GeoJSON source was created
      geojsonSource.setData(newData);
    } else {
      // Create GeoJSON source
      map.current.addSource("crops", {
        type: "geojson",
        data: newData,
      });

      map.current.addLayer({
        id: "circle",
        type: "circle",
        source: "crops",
        paint: {
          "circle-color": "#4264fb",
          "circle-radius": 5,
          "circle-stroke-width": 2,
          "circle-stroke-color": "#ffffff",
          "circle-opacity": 0.2,
        },
      });
    }
  }

  useEffect(() => {
    next();
  }, []);
  useEffect(refreshTask, [task, mapLoaded]);

  return (
    <React.Fragment>
      <LoadingModal open={isUpdating} />
      {task !== null && (
        <div className="border-b border-gray-200 bg-white px-3 py-3 sm:px-6 rounded-md">
          <div className="-ml-4 -mt-2 flex flex-wrap items-center justify-between sm:flex-nowrap">
            <div className="ml-4 mt-2">
              <h3 className="text-md font-medium leading-6 text-gray-900">
                Click the map to draw polygons that correspond to plots
              </h3>
            </div>
            <div className="ml-4 mt-2 flex-shrink-0">
              <div className="flex items-baseline">
                <div className="flex">
                  {task?.date && `Date: ${task.date.substring(0, 10)},`}
                </div>

                <div className="ml-2 flex">
                  {task &&
                    `Location (${task.longitude.toFixed(
                      4,
                    )}, ${task.latitude.toFixed(4)})`}
                </div>
                <button
                  type="button"
                  onClick={() => next(true)}
                  className="inline-flex items-center rounded-md border border-transparent border-gray-300 bg-white px-3 py-2 text-sm font-medium leading-4 text-gray-700 shadow-sm hover:bg-gray-50 ml-4"
                >
                  Skip
                </button>
                <button
                  type="button"
                  onClick={() => next(false)}
                  className="inline-flex items-center rounded-md border border-transparent bg-cyan-600 px-3 py-2 text-sm font-medium leading-4 text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2 ml-4"
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="mt-6">
        <div
          id="map"
          ref={mapContainer}
          className="map-container aspect-square max-h-[75vh] mx-auto"
        ></div>
        {task === null && (
          <div className="mt-12 text-center text-xl font-semibold tracking-tight text-gray-900">
            No parcel for delineation
          </div>
        )}
      </div>
    </React.Fragment>
  );
}

export default withTranslation()(DelineationTask);
