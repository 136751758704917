import React, { useState, useEffect } from "react";
import { getImageUrl } from "../../common/utils/helpers.js";
import { post, unwrapError } from "../../common/utils/api";
import { withTranslation } from "react-i18next";
import { questionCategory } from "../pages/ReviewDetail";
import QuestionBlock from "./QuestionBlock.js";

function ReviewQuestions({
  data,
  showAlert,
  t,
  allData,
  current,
  remainingQuestions,
  remainingList,
  setRemainingList,
}) {
  const [questionDictionary, setQuestionDictionary] = useState({});
  const [radioValues, setRadioValues] = useState({});
  useEffect(() => {
    if (!data) return;
    const tempQuestionDictionary = {};
    const initialRadioValues = {};
    // Iterate over each question from the backend
    data.questions.forEach((question) => {
      const question_number = question.question_code % 1000;
      let crop_number = Math.floor(question.question_code / 1000);
      const question_type = questionCategory[question_number];
      if (question_type === "location") {
        crop_number = -1;
      }
      // get the dictionary for the current crop number
      const current_crop_dictionary = tempQuestionDictionary[crop_number] || {};

      initialRadioValues[question.question_code] = question.answer_code;
      current_crop_dictionary[question_type] =
        current_crop_dictionary[question_type] || [];
      // Append the question to the crop dictionary for the question type
      current_crop_dictionary[question_type].push(question);
      tempQuestionDictionary[crop_number] = current_crop_dictionary;
    });
    setQuestionDictionary(tempQuestionDictionary);
    setRadioValues(initialRadioValues);
  }, [data]); // Depend on data to trigger the effect

  const handleRadioChange = (value, question_index) => {
    const toPostData = {
      data: data.id,
      question_code: question_index,
      answer_code: value,
    };
    post("/data/review/submit/", toPostData)
      .then(function (response) {
        if (radioValues[question_index] === null) {
          remainingQuestions[current] -= 1;
          if (remainingQuestions[current] === 0) {
            let updatedRemainingList = remainingList.filter(
              (item, _) => item !== current,
            );
            setRemainingList(updatedRemainingList);
          }
        }
        setRadioValues((prevState) => ({
          ...prevState,
          [`${question_index}`]: value,
        }));
        data.questions.forEach((question) => {
          if (question.question_code === question_index) {
            question.answer_code = value;
          }
        });
        showAlert({
          type: "success",
          message: t("common.successfullySubmitted", "Successfully submitted"),
        });
      })
      .catch(function (error) {
        showAlert({ type: "error", message: unwrapError(error) });
      });
  };
  let categorizedImages = {};
  let images = {};
  categorizedImages = data?.crops?.map((crop, index) => {
    images[index] = {};
    images[index]["crop"] = crop.images?.map((img) => {
      return {
        imageUrl: getImageUrl(img),
        label: null,
      };
    });

    images[index]["disease"] = crop.disease_images?.map((img) => {
      return {
        imageUrl: getImageUrl(img),
        label: "DISEASE",
      };
    });
    const yield_images = [
      ["size_images", "YIELD - PEGGED", "plot"],
      ["diagonal_images", "YIELD - DIAGONAL", "plot"],
      ["harvested_images", "YIELD - HARVESTED", "harvested"],
      ["yield_images", "YIELD - MEASUREMENT", "weight_value"],
      ["moisture_images", "YIELD - MOISTURE", "weight_value"],
      ["yield_w1_images", "YIELD - W1 - BAG", "weight_value"],
      ["yield_w2_images", "YIELD - W2 - BAG + DEHUSKED", "weight_value"],
      ["yield_w3_images", "YIELD - W3 - BAG + GRAIN", "weight_value"],
      ["yield_w4_images", "YIELD - W4 - SAMPLE", "weight_value"],
      ["yield_w4_drying_images", "YIELD - DRYING", "name_value"],
      ["yield_w5_images", "YIELD - W5 - AFTER DRYING", "weight_value"],
    ];
    yield_images.forEach(([field, title, type]) => {
      crop.yield[field]?.forEach((img) => {
        images[index][type] = images[index][type] || [];
        images[index][type].push({
          imageUrl: getImageUrl(img),
          label: title,
        });
      });
    });
    return images[index];
  });

  return (
    <React.Fragment>
      {/* iterate over the crop dictionary to create multiple blocks if there are multiple crops */}
      {Object.entries(questionDictionary)
        .sort()
        .map(
          ([crop_index, cropQuestions]) =>
            (crop_index === "-1" || data.crops[crop_index]) && (
              <QuestionBlock
                key={crop_index}
                allData={allData}
                data={data}
                current={current}
                categorizedImages={categorizedImages}
                crop_index={crop_index}
                radioValues={radioValues}
                handleRadioChange={handleRadioChange}
                cropQuestions={cropQuestions}
              />
            ),
        )}
    </React.Fragment>
  );
}

export default withTranslation()(ReviewQuestions);
