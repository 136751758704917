import React, { useState } from "react";
import { withTranslation } from "react-i18next";
import tzlookup from "tz-lookup";

import {
  ClockIcon,
  CubeIcon,
  EyeDropperIcon,
  PencilIcon,
  ScaleIcon,
  SignalIcon,
} from "@heroicons/react/20/solid";

import Image from "../../common/components/Image.js";
import { formatTime, getImageUrl } from "../../common/utils/helpers.js";
import { useUser } from "../../user/utils/user";
import { getPhenologyOptions } from "../components/CropItem.js";
import { isCorn, isGrain } from "../components/Yield.js";
import { questionCodeMapper } from "../pages/ReviewDetail.js";

const questionCategory = {
  1: "location",
  2: "location",
  3: "crop_image",
  4: "crop_image",
  5: "crop_image",
  6: "phenology",
  7: "crop",
  8: "crop",
  9: "crop",
  10: "yield",
  11: "yield",
  12: "yield",
  13: "yield",
};

function DescriptionList({ data, showEdit, t }) {
  const [url, setUrl] = useState("");
  const [open, setOpen] = useState(false);
  const color = data.lowAccuracy ? "red-600" : "gray-900";

  const { user } = useUser();
  function onClick(e) {
    setUrl(e);
    setOpen(true);
  }

  // Function to check if any of the provided questionCodes are not approved
  const getUnapprovedQuestions = (category) => {
    return data.questions
      .filter(
        (q) => questionCategory[q.question_code] == category && !q.is_approved,
      )
      .map((q) => questionCodeMapper[q.question_code]);
  };

  // Check if there are any questions that are not approved
  const QuestionStatus = ({ children, category }) => {
    const unapprovedQuestions = getUnapprovedQuestions(category);
    return (
      <div
        className={`${
          unapprovedQuestions?.length > 0 ? "text-red-500 cursor-pointer" : " "
        }`}
        data-tooltip-id={
          unapprovedQuestions?.length > 0 ? "unapproved-tooltip" : undefined
        }
        data-tooltip-html={
          unapprovedQuestions?.length > 0
            ? unapprovedQuestions.join("<br>")
            : undefined
        }
      >
        {React.Children.map(React.Children.toArray(children), (c) => {
          // Inject red background blend if the child is an image
          if (
            unapprovedQuestions?.length > 0 &&
            c?.type?.name == "ImageThumbnail"
          ) {
            return React.cloneElement(c, {
              className: "bg-red-400 bg-blend-overlay",
            });
          }
          return c;
        })}
      </div>
    );
  };

  const hasRejection = data.questions.some((q) => q.is_approved === false);

  return (
    <div className="mt-4 border-b border-gray-200 bg-white px-4 py-5 sm:px-6 sm:rounded-lg shadow">
      <Image url={url} open={open} setOpen={setOpen} />
      <header className="-ml-4 -mt-2 md:flex md:items-center md:justify-between md:flex-row sm:flex-nowrap flex flex-col space-x-2 items-center">
        <div className="ml-4 mt-2 min-w-0 flex-1 space-y-2">
          <div className="flex">
            {data.name && (
              <h1 className="text-2xl font-bold text-gray-900 mr-4">
                {data.name}
              </h1>
            )}
            {user.user.is_supervisor && hasRejection && (
              <button
                type="button"
                className="inline-flex justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                onClick={showEdit}
              >
                <PencilIcon className="-ml-0.5 h-5 w-5 text-gray-400" />
                Edit
              </button>
            )}
          </div>

          <div className="flex flex-col md:flex-row md:space-x-8">
            <div className="mt-2 flex">
              <SignalIcon
                className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                aria-hidden="true"
              />
              <div data-testid="accuracy-value">
                <QuestionStatus category="location">
                  <span className={`text-sm`}>Accuracy:</span>
                  <span className={`ml-1 text-sm text-${color}`}>
                    {data.manualLocation
                      ? "Manual entry"
                      : `${data.accuracy.toFixed(0)} m`}
                  </span>
                </QuestionStatus>
              </div>
            </div>
            <div className="mt-2 flex">
              <EyeDropperIcon
                className="mr-1.5 ml-1 h-5 w-5 flex-shrink-0 text-gray-400"
                aria-hidden="true"
              />
              <span
                className="text-sm text-gray-500"
                data-testid="irrigation-value"
              >
                <QuestionStatus>{data.irrigation}</QuestionStatus>
              </span>
            </div>
            <div className="mt-2 flex">
              <ClockIcon
                className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                aria-hidden="true"
              />
              <span className="text-sm text-gray-500" data-testid="time-value">
                {formatTime(
                  data.createddate,
                  data.latitude && data.longitude
                    ? tzlookup(data.latitude, data.longitude)
                    : undefined,
                )}
              </span>
            </div>
          </div>
        </div>
      </header>

      {data.crops?.map((c, i) => (
        <CropType
          crop={c}
          key={i}
          onClick={onClick}
          t={t}
          index={i}
          QuestionStatus={QuestionStatus}
        />
      ))}
    </div>
  );
}

function ImageThumbnail({ resource, className, onClick }) {
  return (
    <button
      className={`h-full w-full aspect-square block bg-origin-padding bg-left-top bg-cover bg-no-repeat z-0 shadow-inner ${className}`}
      onClick={() => onClick(resource.imageUrl)}
      style={{
        backgroundImage: `url(${resource.imageUrl || ""})`,
      }}
    >
      <img
        src={resource.imageUrl || ""}
        alt={resource.title}
        className="w-full aspect-square hidden"
      />
    </button>
  );
}

function CropType({ crop, onClick, t, index, QuestionStatus }) {
  const images = [];
  crop.images?.forEach((img) => {
    images.push({
      imageUrl: getImageUrl(img),
      label: null,
    });
  });
  crop.disease_images?.forEach((img) => {
    images.push({
      imageUrl: getImageUrl(img),
      label: "DISEASE",
    });
  });

  const yield_images = [
    ["size_images", "YIELD - PEGGED"],
    ["diagonal_images", "YIELD - DIAGONAL"],
    ["harvested_images", "YIELD - HARVESTED"],
    ["yield_images", "YIELD - MEASUREMENT"],
    ["moisture_images", "YIELD - MOISTURE"],
    ["yield_w1_images", "YIELD - W1 - BAG"],
    ["yield_w2_images", "YIELD - W2 - BAG + DEHUSKED"],
    ["yield_w3_images", "YIELD - W3 - BAG + GRAIN"],
    ["yield_w4_images", "YIELD - W4 - SAMPLE"],
    ["yield_w4_drying_images", "YIELD - DRYING"],
    ["yield_w5_images", "YIELD - W5 - AFTER DRYING"],
  ];
  yield_images.forEach(([field, title]) => {
    crop.yield[field]?.forEach((img) => {
      images.push({
        imageUrl: getImageUrl(img),
        label: title,
      });
    });
  });

  const phenologyOptionsList = getPhenologyOptions({ t });

  return (
    <React.Fragment>
      <div className="my-4 grid grid-cols-1 sm:grid-cols-3 md:grid-cols-4 rounded-lg border">
        <div className="overflow-hidden rounded-l-lg bg-white col-span-1 border-r border-gray-300">
          <div className="bg-gray-50 px-5 py-3 border-b border-gray-300">
            <div className="text-sm">
              <div className="w-0 flex-1">
                <dl>
                  <dd>
                    <div
                      className="text-lg font-medium text-gray-900"
                      data-testid={`${index}-crop`}
                    >
                      <QuestionStatus category="crop">
                        {crop.crop}
                        {crop.crop === "other" && `: ${crop.otherCropType}`}
                        {crop.crop === "preparedland" &&
                          `: ${crop.preparedCropType}`}
                      </QuestionStatus>
                    </div>
                  </dd>
                </dl>
              </div>
            </div>
          </div>
          <div className="py-2">
            <div className="flex items-center">
              <div className="ml-5 w-0 flex-1">
                <dl className="gap-y-8">
                  {crop.phenology && crop.crop in phenologyOptionsList && (
                    <dd className="my-4 flex items-center text-sm text-gray-900">
                      <CubeIcon
                        className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                        aria-hidden="true"
                      />
                      <span data-testid={`${index}-phenology`}>
                        <QuestionStatus category="phenology">
                          {"Stage " +
                            phenologyOptionsList[crop.crop][crop.phenology]}
                        </QuestionStatus>
                      </span>
                    </dd>
                  )}
                  <dd className="my-4 flex items-center text-sm text-gray-900">
                    <CubeIcon
                      className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                      aria-hidden="true"
                    />
                    <span data-testid={`${index}-cropDisease`}>
                      <QuestionStatus category="disease">
                        {crop.disease === "yes"
                          ? "Has disease or deficiency"
                          : "No disease"}
                        {crop.comment && `: ${crop.comment}`}
                      </QuestionStatus>
                    </span>
                  </dd>
                  {crop.yield.recording === "yes" && (
                    <dd className="my-4 flex text-sm text-gray-900 items-start">
                      <ScaleIcon
                        className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                        aria-hidden="true"
                      />
                      <div>
                        <QuestionStatus category="yield">
                          <span>Yield</span>
                        </QuestionStatus>
                        <ul className="list-disc list-inside text-xs">
                          <li data-testid={`${index}-cropSize`}>
                            <QuestionStatus category="yield">
                              Size: {crop.yield.size}
                            </QuestionStatus>
                          </li>
                          <li data-testid={`${index}-cropDiagonal`}>
                            <QuestionStatus category="yield">
                              Diagonal: {crop.yield.diagonal}
                            </QuestionStatus>
                          </li>
                          {isGrain(crop.crop) ? (
                            <>
                              {isCorn(crop.crop) && (
                                <>
                                  <li data-testid={`${index}-nPlants`}>
                                    <QuestionStatus category="yield">
                                      Number of plants: {crop.yield.n_plants}
                                    </QuestionStatus>
                                  </li>
                                  <li data-testid={`${index}-nCobs`}>
                                    <QuestionStatus category="yield">
                                      Number of cobs: {crop.yield.n_cobs}
                                    </QuestionStatus>
                                  </li>
                                </>
                              )}
                              {crop.yield.using_moisture === "yes" && (
                                <>
                                  <li data-testid={`${index}-yield`}>
                                    <QuestionStatus category="yield">
                                      Yield: {crop.yield.yield}
                                    </QuestionStatus>
                                  </li>
                                  <li data-testid={`${index}-moisture`}>
                                    <QuestionStatus category="yield">
                                      Moisture: {crop.yield.moisture}
                                    </QuestionStatus>
                                  </li>
                                </>
                              )}
                              {crop.yield.using_moisture === "no" && (
                                <>
                                  <li data-testid={`${index}-yield_w1`}>
                                    <QuestionStatus category="yield">
                                      W1: {crop.yield.yield_w1}
                                    </QuestionStatus>
                                  </li>
                                  {isCorn(crop.crop) && (
                                    <li data-testid={`${index}-yield_w2`}>
                                      <QuestionStatus category="yield">
                                        W2: {crop.yield.yield_w2}
                                      </QuestionStatus>
                                    </li>
                                  )}
                                  <li data-testid={`${index}-yield_w3`}>
                                    <QuestionStatus category="yield">
                                      W3: {crop.yield.yield_w3}
                                    </QuestionStatus>
                                  </li>
                                  <li data-testid={`${index}-yield_w4`}>
                                    <QuestionStatus category="yield">
                                      W4: {crop.yield.yield_w4}
                                    </QuestionStatus>
                                  </li>
                                  <li data-testid={`${index}-yield_w5`}>
                                    <QuestionStatus category="yield">
                                      W5: {crop.yield.yield_w5}
                                    </QuestionStatus>
                                  </li>
                                </>
                              )}
                            </>
                          ) : (
                            <li>
                              <QuestionStatus category="yield">
                                Yield: {crop.yield.yield}
                              </QuestionStatus>
                            </li>
                          )}
                        </ul>
                      </div>
                    </dd>
                  )}
                </dl>
              </div>
            </div>
          </div>
        </div>
        <div className="container mx-auto col-span-2 md:col-span-3">
          <div className="relative overflow-hidden rounded-r-lg">
            <div className="gap-1 relative flex overflow-auto z-0">
              {images.map((resource, index) => {
                return (
                  <div key={index} className="relative w-64 h-64 snap-start">
                    {resource.label && (
                      <div className="m-2 absolute inline-flex items-center rounded-md border border-transparent bg-gray-900 opacity-80 px-3 py-2 text-sm font-medium leading-4 text-white shadow-sm">
                        {resource.label}
                      </div>
                    )}
                    <QuestionStatus category="crop_image">
                      <ImageThumbnail resource={resource} onClick={onClick} />
                    </QuestionStatus>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default withTranslation()(DescriptionList);
