import React from "react";
import { Field, ErrorMessage } from "formik";
import { QuestionMarkCircleIcon } from "@heroicons/react/20/solid";
import { withTranslation } from "react-i18next";

function Select({
  label,
  name,
  options,
  validate,
  required,
  onChange,
  showLegend,
  setIsOpen,
  t,
}) {
  const texts = Array.isArray(options) ? options : Object.values(options);
  const values = Array.isArray(options) ? options : Object.keys(options);

  let fieldProps = {};
  if (onChange) {
    fieldProps.onChange = onChange;
  }

  return (
    <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
      <label
        htmlFor={name}
        className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
      >
        <span>
          {label}
          {required ? (
            <span className="text-red-800 text-superscript">*</span>
          ) : null}
          {showLegend ? (
            <QuestionMarkCircleIcon
              onClick={() => setIsOpen(true)}
              className="h-5 w-5 text-gray-400 inline-block ml-1"
            />
          ) : null}
        </span>
      </label>
      <div className="mt-1 sm:col-span-2 sm:mt-0">
        <Field
          as="select"
          id={name}
          name={name}
          validate={validate}
          {...fieldProps}
          className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:max-w-xs sm:text-sm"
        >
          <option value="" disabled>
            {t("common.selectFromOptionsBelow", "Select from options below")}
          </option>
          {options &&
            values.map((v, i) => (
              <option value={v} key={v}>
                {texts[i]}
              </option>
            ))}
        </Field>
        <p className={`text-sm font-medium text-red-800 mt-0`}>
          <ErrorMessage name={name} />
        </p>
      </div>
    </div>
  );
}

export default withTranslation()(Select);
