import React from "react";
import { FieldArray } from "formik";
import { withTranslation } from "react-i18next";

import CropItem from "./CropItem.js";

export function makeNullCrop() {
  return {
    crop: "",
    phenology: "",
    otherCropType: "",
    preparedCropType: "",
    disease: "",
    comment: "",
    yield: {
      recording: "",
      size: "",
      size_images: [],
      diagonal: "",
      diagonal_images: [],
      harvested_images: [],
      n_plants: "",
      n_cobs: "",
      using_moisture: "",
      yield: "",
      yield_images: [],
      moisture: "",
      moisture_images: [],
      yield_w1: "",
      yield_w1_images: [],
      yield_w2: "",
      yield_w2_images: [],
      yield_w3: "",
      yield_w3_images: [],
      yield_w4: "",
      yield_w4_images: [],
      yield_w4_drying_images: [],
      yield_w5: "",
      yield_w5_images: [],
    },
    images: [],
    disease_images: [],
  };
}

function Crop({ t }) {
  return (
    <div className="space-y-6 pt-8 sm:space-y-5 sm:pt-10">
      <div>
        <h3 className="text-lg font-medium leading-6 text-gray-900">
          {t("crop.cropInformationThisSeason", "Crop information this season")}
        </h3>
      </div>
      <FieldArray name="crops" validateOnChange>
        {({ form: { values, setValues, validateForm }, ...arrayHelpers }) => {
          return (
            <React.Fragment>
              {values.crops.map((friend, index) => (
                <React.Fragment key={index}>
                  {index > 0 && (
                    <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t-2 sm:border-slate-400 sm:pt-5">
                      <label className="block font-medium text-gray-700 sm:mt-px">
                        {t("crop.cropType", "Crop Type")} {index + 1}
                      </label>
                    </div>
                  )}
                  <CropItem index={index} images={true} t={t} />
                  {index === 0 || index !== values.crops.length - 1 ? null : (
                    <button
                      onClick={() => arrayHelpers.remove(index)}
                      type="button"
                      className="inline-flex justify-center rounded-lg text-sm font-semibold py-2.5 px-4 bg-slate-400 text-white hover:bg-slate-600 w-full disabled:bg-gray-500 disabled:cursor-not-allowed"
                    >
                      {t("plot.removeCropType", "Remove Crop Type ")}{" "}
                      {index + 1} {t("plot.inThePlot", "in the plot")}
                    </button>
                  )}
                  {index < values.crops.length - 1 || index >= 2 ? null : (
                    <button
                      onClick={() =>
                        arrayHelpers.insert(index + 1, makeNullCrop())
                      }
                      type="button"
                      className="inline-flex justify-center rounded-lg text-sm font-semibold py-2.5 px-4 bg-cyan-600 text-white hover:bg-cyan-600 w-full disabled:bg-gray-500 disabled:cursor-not-allowed"
                    >
                      {t(
                        "plot.addAnotherCropInThePlot",
                        "Add another crop in the plot",
                      )}
                    </button>
                  )}
                </React.Fragment>
              ))}
            </React.Fragment>
          );
        }}
      </FieldArray>
    </div>
  );
}

export default withTranslation()(Crop);
