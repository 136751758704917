import React, { useState } from "react";
import { Link } from "react-router-dom";
import { PlusIcon, EnvelopeIcon } from "@heroicons/react/20/solid";
import { useLiveQuery } from "dexie-react-hooks";
import { Dialog } from "@headlessui/react";
import FadeLoader from "react-spinners/FadeLoader";
import { withTranslation } from "react-i18next";
import moment from "moment";

import { useUser } from "../../user/utils/user";
import { db, loadAllImages, deleteData } from "../utils/data";
import { validateRecord } from "./Form";
import { post, unwrapError } from "../../common/utils/api";
import { isInstalled } from "../../common/components/InstallPrompt";
import { isCorn } from "../components/Yield";
import List from "../../common/components/List.js";
import Popup from "../../common/components/Popup.js";
import Leaderboard from "../../common/components/Leaderboard.js";
import ErrorBoundary from "../../common/utils/helpers.js";

function validateImages(d) {
  function validate(images, c, name) {
    if (images) {
      for (var i = 0; i < images.length; i++) {
        const image = images[i]?.image;
        if (!image || typeof image !== "string" || !image.startsWith("data:")) {
          throw {
            response: {
              data: `Problem with ${name} image #${i + 1} for crop #${c + 1}`,
            },
          };
        }
      }
    }
  }
  for (var c = 0; c < d.crops.length; c++) {
    const crop = d.crops[c];
    validate(crop.images, c, "crop");
    validate(crop.disease_images, c, "disease");
    // Legacy
    validate(crop.yield_images, c, "yield");

    validate(crop.yield?.yield_images, c, "yield");
    validate(crop.yield?.size_images, c, "size");
    validate(crop.yield?.diagonal_images, c, "diagonal");
    validate(crop.yield?.harvested_images, c, "harvested");
    validate(crop.yield?.moisture_images, c, "moisture");
    validate(crop.yield?.yield_w1_images, c, "yield w1");
    validate(crop.yield?.yield_w2_images, c, "yield w2");
    validate(crop.yield?.yield_w3_images, c, "yield w3");
    validate(crop.yield?.yield_w4_images, c, "yield w4");
    validate(crop.yield?.yield_w4_drying_images, c, "yield w4 drying");
    validate(crop.yield?.yield_w5_images, c, "yield w5");
  }
}

function validateYieldData(d) {
  if (d.crops) {
    for (var c = 0; c < d.crops.length; c++) {
      const yield_image_required = [];
      const yield_field_required = [];
      const yield_field_positive = [];

      const y = d.crops[c].yield;
      if (y?.using_moisture === "yes") {
        yield_image_required.push("yield_images", "moisture_images");
        yield_field_positive.push("yield", "moisture");
      } else if (y?.using_moisture === "no") {
        yield_image_required.push(
          "yield_w1_images",
          "yield_w3_images",
          "yield_w4_images",
          "yield_w5_images",
        );
        yield_field_positive.push(
          "yield_w1",
          "yield_w3",
          "yield_w4",
          "yield_w5",
        );
        if (isCorn(d.crops[c].crop)) {
          yield_image_required.push("yield_w2_images");
          yield_field_positive.push("yield_w2");
        }
      }

      // Yield required images
      yield_image_required.forEach((yield_image_field) => {
        if (y?.[yield_image_field]?.length === 0) {
          throw {
            response: {
              data: `Yield images incomplete for crop #${c + 1}`,
            },
          };
        }
      });

      // Yield required positive fields
      yield_field_positive.forEach((field) => {
        if (!y?.[field] || y?.[field] <= 0) {
          throw {
            response: {
              data: `Yield information incomplete for crop #${c + 1}`,
            },
          };
        }
      });
    }
  }
}

const AddFormButton = withTranslation()(({ t }) => (
  <Link to="/data/form">
    <button
      type="button"
      className="inline-flex items-center rounded-md border border-transparent bg-cyan-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2 w-full sm:w-auto justify-center"
    >
      <PlusIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
      {t("common.newDataCollection", "New Data Collection")}
    </button>
  </Link>
));

const EmptyState = withTranslation()(({ t }) => (
  <div className="text-center">
    <svg
      className="mx-auto h-12 w-12 text-gray-400"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
      aria-hidden="true"
    >
      <path
        vectorEffect="non-scaling-stroke"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z"
      />
    </svg>
    <h3 className="mt-2 text-sm font-medium text-gray-900">
      {t("common.noSavedDataRecords", "No saved data records")}
    </h3>
    <p className="mt-1 text-sm text-gray-500">
      {t(
        "common.getStartedByLoggingANewRecord",
        "Get started by logging a new record.",
      )}
    </p>
    <div className="mt-6">
      <AddFormButton />
    </div>
  </div>
));

export function Home({ showAlert, t }) {
  const [open, setOpen] = useState(false);
  const [popup, setPopup] = useState({
    title: "",
    message: "",
    button: "",
    onSubmit: null,
    type: "error",
  });
  const [syncing, setSyncing] = useState(false);
  const [errors, setErrors] = useState({});

  const { user } = useUser();

  const data = useLiveQuery(() => db.data.reverse().toArray());

  async function submit() {
    setSyncing(true);
    setErrors({});
    const storage = navigator.storage.estimate
      ? await navigator.storage.estimate()
      : {};
    const device = {
      userAgent: navigator.userAgent,
      hardwareConcurrency: navigator.hardwareConcurrency,
      deviceMemory: navigator.deviceMemory,
      storage: storage,
      installed: isInstalled(),
      screen: { height: window.screen.height, width: window.screen.width },
    };
    const app = {
      timestamp: process.env.REACT_APP_CI_COMMIT_TIMESTAMP,
      branch: process.env.REACT_APP_CI_COMMIT_REF_NAME,
      commit: process.env.REACT_APP_CI_COMMIT_SHA,
      pipeline: process.env.REACT_APP_CI_PIPELINE_ID,
      projectPipeline: process.env.REACT_APP_CI_PIPELINE_IID,
    };
    const newErrors = {};

    // post each data record as a separate promise
    data
      .reduce((chain, d) => {
        const { createddate, updateddate, ...rest } = d;
        return chain
          .then(() => validateYieldData(d))
          .then(() => loadAllImages(d))
          .then(() => validateImages(d))
          .then(() => {
            const errors = validateRecord(d, t);
            if (Object.keys(errors).length > 0) {
              throw {
                response: {
                  data: "Error in recorded data. Click Edit to view and correct issues",
                },
              };
            }
          })
          .then(() =>
            post("/data/", {
              data: { ...rest, device, app },
              form_created_at: createddate,
              form_updated_at: updateddate,
              form_created_date: moment(createddate).format("YYYY-MM-DD"),
              form_updated_date: moment(createddate).format("YYYY-MM-DD"),
            }),
          )
          .then(() => deleteData(d))
          .catch((error) => {
            let e = error.response.data;
            if (e?.data?.[0]) {
              e = e.data[0];
            }
            newErrors[d.id] = e;
          });
      }, Promise.resolve())
      .then(() => {
        if (Object.keys(newErrors).length > 0) {
          showAlert({
            type: "error",
            message: "Errors occurred during submission",
          });
        } else {
          showAlert({
            type: "success",
            message: t(
              "common.successfullySubmitted",
              "Successfully submitted",
            ),
          });
        }
      })
      .catch(function (error) {
        showAlert({ type: "error", message: unwrapError(error) });
      })
      .finally(() => {
        setSyncing(false);
        setErrors(newErrors);
      });
  }

  function confirmSubmit() {
    setPopup({
      title: t("common.submitRecords", "Submit Records?"),
      message: t(
        "common.areYouSureYouWantToSubmitTheseRecords",
        "Are you sure you want to submit these records? They will no longer be available after submission. ",
      ),
      button: t("common.submit", "Submit"),
      onSubmit: submit,
      type: "warning",
    });
    setOpen(true);
  }

  function confirmDelete(d) {
    setPopup({
      title: t("common.deleteRecord", "Delete Record?"),
      message: t(
        "common.areYouSureYouWantToDeleteThisRecord",
        "Are you sure you want to delete this record?",
      ),
      button: t("common.delete", "Delete"),
      onSubmit: () => deleteData(d),
      type: "error",
    });
    setOpen(true);
  }

  return (
    data && (
      <div className="mx-auto max-w-7xl">
        <SyncIndicator open={syncing} />
        <Popup
          open={open}
          setOpen={setOpen}
          title={popup.title}
          message={popup.message}
          button={popup.button}
          onSubmit={popup.onSubmit}
          type={popup.type}
        />
        <ErrorBoundary>
          {data?.length > 0 ? (
            <div>
              <div className="sm:flex sm:items-center">
                <div className="sm:flex-auto">
                  <h1 className="text-xl font-semibold text-gray-900">
                    {t("common.recordedData", "Recorded Data")}
                  </h1>
                  <p className="mt-2 text-sm text-gray-700">
                    {t(
                      "common.allCollectedDataThatHasNotBeenSubmitted",
                      "All collected data that has not yet been submitted",
                    )}
                  </p>
                </div>
                <div className="mt-4 sm:mt-0 sm:ml-16 grid sm:flex sm:flex-none items-center">
                  <AddFormButton />
                  {user ? (
                    <button
                      type="submit"
                      onClick={confirmSubmit}
                      className="sm:ml-3 inline-flex justify-center rounded-md border border-transparent bg-green-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 align-top w-full sm:w-auto mt-2 sm:mt-0"
                    >
                      <EnvelopeIcon
                        className="-ml-1 mr-2 h-5 w-5"
                        aria-hidden="true"
                      />
                      {t("common.submitAll", "Submit all")}
                    </button>
                  ) : (
                    <React.Fragment />
                  )}
                </div>
              </div>
              <div data-testid="data-list">
                <List content={data} errors={errors} onDelete={confirmDelete} />
              </div>
            </div>
          ) : (
            <EmptyState />
          )}
          {user && navigator.onLine && <Leaderboard />}
        </ErrorBoundary>
      </div>
    )
  );
}

function SyncIndicator({ open }) {
  return (
    <Dialog open={open} onClose={() => false} className="relative z-50">
      {/* The backdrop, rendered as a fixed sibling to the panel container */}
      <div
        data-testid={open ? "syncing" : "notsyncing"}
        className="fixed inset-0 bg-black/30"
        aria-hidden="true"
      />

      {/* Full-screen container to center the panel */}
      <div className="fixed inset-0 flex items-center justify-center p-4">
        <FadeLoader loading={true} size={150} />
      </div>
    </Dialog>
  );
}

export default withTranslation()(Home);
