const makeHeaders = (user, t) => {
  const header = [];

  if (user && user.user.roles?.includes("Delineation2")) {
    header.push({
      to: "task",
      title: t("user.delineation", "Delineation"),
      desc: t("user.markParcelBoundaries", "Mark parcel boundaries on map."),
    });

    if (user.user.is_supervisor || user.user.is_superuser) {
      header.push({
        to: "review",
        title: t("user.delineationReview", "Review Delineation"),
        desc: t("user.reviewParcelBoundaries", "Review delineated parcels."),
      });
    }

    if (user.user.is_superuser) {
      header.push({
        to: "superuser",
        title: t("user.supervisorView", "Superuser View"),
        desc: t("user.viewTheSubmittedResults", "View all submitted data."),
      });
    }
  }
  return header;
};
export default makeHeaders;
