import { useRef, useEffect } from "react";
import { blueIcon, API_KEY, ChangeView } from "../pages/ReviewDetail";
import {
  MapContainer,
  Marker,
  Circle,
  CircleMarker,
  ScaleControl,
} from "react-leaflet";
import ReactLeafletGoogleLayer from "react-leaflet-google-layer";

function MapData({ allData, data, current }) {
  const circleRefs = useRef();
  const mapRef = useRef();
  useEffect(() => {
    if (mapRef.current && data.latitude && data.longitude) {
      mapRef.current.setView(
        [data.latitude, data.longitude],
        mapRef.current.getZoom(),
      );
    }
  }, [data]);

  return (
    <div className="relative w-full h-64">
      <MapContainer
        className=" border border-gray-200"
        center={[data.latitude, data.longitude]}
        style={{
          height: "100%",
          width: "100%",
        }}
        zoom={18}
        scrollWheelZoom={false}
        dragging={false}
        zoomControl={false}
        doubleClickZoom={false}
      >
        <ChangeView markers={allData} current={current} />
        <ReactLeafletGoogleLayer apiKey={API_KEY} type={"satellite"} />
        <Circle
          center={{
            lat: data.latitude,
            lng: data.longitude,
          }}
          ref={(r) => {
            circleRefs.current = r;
          }}
          radius={10}
        >
          <Marker
            position={{
              lat: data.latitude,
              lng: data.longitude,
            }}
            icon={blueIcon}
            autoPan={false}
          />

          <CircleMarker
            center={{
              lat: data.latitude,
              lng: data.longitude,
            }}
            color={"gray"}
            fillOpacity={0}
            radius={1}
          />
        </Circle>
        <ScaleControl />
      </MapContainer>
    </div>
  );
}
export default MapData;
