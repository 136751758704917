import React, { useState, useEffect, useRef, useMemo } from "react";
import { withTranslation } from "react-i18next";
import mapboxgl from "mapbox-gl";
import MapboxDraw from "@mapbox/mapbox-gl-draw";
import moment from "moment";

import "mapbox-gl/dist/mapbox-gl.css";
import "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/20/solid";

import LoadingModal from "../../common/components/LoadingModal";
import { get, post, unwrapError } from "../../common/utils/api";
import Map from "../components/Map";
import Palette from "../components/Palette";
import {
  categories,
  nameToColor,
  mapboxDrawStyle,
  getBounds,
  setSentinel2Visibility,
  addSentinel2Tiles,
} from "../utils/map.js";

function Landcover2Task({ showAlert, t }) {
  const [groupOptions, setGroupOptions] = useState();
  const [selectedGroup, setSelectedGroup] = useState("All");
  const [scenes, setScenes] = useState([]);
  const [currentScene, setCurrentScene] = useState();
  const [sceneVisible, setSceneVisible] = useState(true);
  const [isUpdating, setIsUpdating] = useState(true);

  const map = useRef(null);
  const categoryRef = useRef();

  const [task, setTask] = useState(null);
  const [mapLoaded, setMapLoaded] = useState(false);

  const draw = useMemo(() => {
    return new MapboxDraw({
      displayControlsDefault: false,
      // Select which mapbox-gl-draw control buttons to add to the map.
      controls: {
        polygon: true,
        trash: true,
      },
      // Set mapbox-gl-draw to draw by default.
      // The user does not have to click the polygon control button first.
      defaultMode: "draw_polygon",
      userProperties: true,
      styles: mapboxDrawStyle,
    });
  }, []);

  function updateScenes(bbox, date) {
    const startDate = moment(date).subtract(1, "months").toISOString();
    const endDate = moment(date).add(1, "months").toISOString();

    var search_query = {
      datetime: `${startDate}/${endDate}`,
      limit: 200,
      collections: ["sentinel-2-l2a"],
      bbox: bbox,
    };
    fetch("https://earth-search.aws.element84.com/v1/search", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(search_query),
    })
      .then((res) => res.json())
      .then((data) => {
        var scenes = data.features.map((f) => {
          return {
            date: f.properties.start_datetime || f.properties.datetime,
            scene_id: f.id,
          };
        });
        // Ascending
        scenes.sort(function (a, b) {
          return new Date(a.date) - new Date(b.date);
        });

        // Find closest date to input
        const targetDate = moment(date).toDate();
        let closestIndex;
        let closestDiff = Infinity;
        scenes.forEach((d, i) => {
          const diff = Math.abs(new Date(d.date) - targetDate);
          if (diff < closestDiff) {
            closestIndex = i;
            closestDiff = diff;
          }
        });

        setScenes(scenes);
        setCurrentScene(closestIndex);
        setSceneVisible(true);
      });
  }

  useEffect(() => {
    if (mapLoaded) {
      setSentinel2Visibility(map.current, sceneVisible);
    }
  }, [sceneVisible, mapLoaded]);

  async function submit() {
    const parcels = [];
    draw.getAll().features.forEach((f) => {
      // There seems to be a phantom geometry last in the feature collection
      // that is 1-2 coordinates in the same place, ignore this one
      if (f.geometry.coordinates[0].length > 2) {
        parcels.push({ data: f });
      }
    });
    const payload = {
      data: task.data,
      width: task.width,
      scene_datetime: scenes[currentScene].date,
      scene_id: scenes[currentScene].scene_id,
      landcover2_submission_set: parcels,
    };
    return post("/landcover2/task/submit/", payload)
      .then(() => {
        draw.deleteAll();
        draw.changeMode("draw_polygon");
        showAlert({
          type: "success",
          message: t("common.successfullySubmitted", "Successfully submitted"),
        });
      })
      .catch(function (error) {
        showAlert({ type: "error", message: unwrapError(error) });
      });
  }

  const getTask = async () => {
    return get(`/landcover2/task/?group=${selectedGroup}`).then((response) => {
      return response.data;
    });
  };

  const nextTask = async (skip) => {
    setIsUpdating(true);
    if (task && !skip) {
      await submit();
    }

    const newTask = await getTask();
    const bounds = getBounds(newTask);
    updateScenes(bounds.flat(), newTask.date);
    setTask(newTask);
    setIsUpdating(false);
  };
  useEffect(() => {
    nextTask();
  }, [selectedGroup]);

  const getGroups = async () => {
    get("/users/groups").then((response) => {
      setGroupOptions(["All", ...response.data]);
    });
  };
  useEffect(() => {
    getGroups();
  }, []);

  const isEnd = currentScene === scenes.length - 1;
  function previousScene() {
    if (currentScene !== 0) {
      setCurrentScene(currentScene - 1);
      setSceneVisible(true);
    }
  }
  function nextScene() {
    if (!isEnd) {
      setCurrentScene(currentScene + 1);
      setSceneVisible(true);
    }
  }

  useEffect(() => {
    if (mapLoaded && currentScene) {
      const sceneId = scenes[currentScene].scene_id;
      addSentinel2Tiles(map.current, sceneId);
    }
  }, [mapLoaded, currentScene, scenes]);

  return (
    <React.Fragment>
      <LoadingModal open={isUpdating} />
      <div className="border-b border-gray-200 bg-white px-3 py-3 sm:px-6 rounded-md">
        <div className="-ml-4 -mt-2 flex flex-wrap items-center justify-between sm:flex-nowrap">
          <div className="ml-4 mt-2">
            <h3 className="text-md font-medium leading-6 text-gray-900">
              Click the map to draw polygons that correspond to plots
            </h3>
          </div>
          {groupOptions && groupOptions.length > 2 && (
            <div className="ml-4 mt-2 flex items-baseline">
              Group:
              <select
                className="ml-2 max-w-lg rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:max-w-xs sm:text-sm"
                onChange={(e) => {
                  setSelectedGroup(e.target.value);
                }}
              >
                {groupOptions.map((g) => (
                  <option key={g} value={g}>
                    {g}
                  </option>
                ))}
              </select>
            </div>
          )}
          <div className="ml-4 mt-2 flex-shrink-0">
            <div className="flex items-baseline">
              <div className="flex">
                {task?.date && `Date: ${task.date.substring(0, 10)},`}
              </div>

              <div className="ml-2 flex">
                {task &&
                  `Location (${task.longitude.toFixed(
                    4,
                  )}, ${task.latitude.toFixed(4)})`}
              </div>
              <button
                type="button"
                onClick={() => nextTask(true)}
                className="inline-flex items-center rounded-md border border-transparent border-gray-300 bg-white px-3 py-2 text-sm font-medium leading-4 text-gray-700 shadow-sm hover:bg-gray-50 ml-4"
              >
                Skip
              </button>
              <button
                type="button"
                onClick={() => nextTask(false)}
                className="inline-flex items-center rounded-md border border-transparent bg-cyan-600 px-3 py-2 text-sm font-medium leading-4 text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2 ml-4"
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
      {task && (
        <div className="mt-6 flex flex-row justify-center">
          <Map
            map={map}
            interactive={true}
            task={task}
            draw={draw}
            categoryRef={categoryRef}
            mapLoaded={mapLoaded}
            setMapLoaded={setMapLoaded}
          />
          <div className="mt-0 ml-6 flex-shrink-0 flex-grow-0">
            <Palette categoryRef={categoryRef} interactive={true} />
          </div>
        </div>
      )}
      {scenes.length > 0 && (
        <div className="text-center">
          <div className="mt-8 border-b border-gray-200 bg-white px-3 py-3 sm:px-6 rounded-md inline-flex">
            <div className="-ml-4 -mt-2 flex flex-wrap items-center justify-center sm:flex-nowrap">
              <div className="ml-4 mt-2 flex items-center">
                Real-time satellite image date:
                <div className="flex items-center ml-2">
                  <div>
                    <button
                      type="button"
                      onClick={previousScene}
                      disabled={currentScene === 0}
                      className="relative inline-flex items-center rounded-l-md border border-gray-300 bg-white px-2 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-20 disabled:opacity-50"
                    >
                      <span className="sr-only">Previous</span>

                      <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
                    </button>
                  </div>
                  <div className="flex justify-center">
                    <div className="relative border-y border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-500 focus:z-20 w-32 text-center">
                      {moment(scenes[currentScene].date).format("YYYY-MM-DD")}
                    </div>
                  </div>
                  <div>
                    <button
                      type="button"
                      onClick={nextScene}
                      disabled={isEnd}
                      className="relative inline-flex items-center rounded-r-md border border-gray-300 bg-white px-2 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-20 disabled:opacity-50"
                    >
                      <span className="sr-only">Next</span>
                      <ChevronRightIcon
                        className="h-5 w-5"
                        aria-hidden="true"
                      />
                    </button>
                  </div>
                </div>
              </div>
              <div className="ml-8 mt-2 flex-shrink-0">
                <div className="flex items-baseline space-x-2">
                  <div className="flex">Show real-time satellite image:</div>
                  <input
                    id="comments"
                    aria-describedby="comments-description"
                    name="comments"
                    type="checkbox"
                    checked={sceneVisible}
                    onChange={() => setSceneVisible((visible) => !visible)}
                    className="h-4 w-4 rounded border-gray-300 text-cyan-600 focus:ring-cyan-500"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
}

export default withTranslation()(Landcover2Task);
