import { useFormikContext } from "formik";
import { withTranslation } from "react-i18next";

import Select from "../../common/components/form/Select.js";
import ImageInput from "../../common/components/form/ImageInput.js";
import NumericInput from "../../common/components/form/NumericInput.js";

export function isGrain(crop) {
  return ["corn", "cowpea", "rice", "soybean"].includes(crop);
}
export function isCorn(crop) {
  return ["corn"].includes(crop);
}

function YieldInput({ index, t, images }) {
  const {
    values: { crops },
  } = useFormikContext();

  function validateRequire(value) {
    let error;
    if (!value) {
      error = t("crop.requiredField", "Required field");
    }
    return error;
  }

  const yesNoOptions = {
    no: t("crop.yieldNo", "No"),
    yes: t("crop.yieldYes", "Yes"),
  };

  const sizeOptions = ["3m x 3m", "4.5m x 4.5m", "5m x 5m", "6m x 6m"];

  return (
    <>
      <Select
        label={t("crop.recordingYield", "Are you recording yield?")}
        key={`crops[${index}].yield.recording`}
        name={`crops[${index}].yield.recording`}
        options={yesNoOptions}
        validate={validateRequire}
        required={true}
        showLegend={false}
      />
      {crops[index].yield?.recording === "yes" && (
        <>
          <Select
            label={t("crop.yieldSize", "Size of subplot")}
            key={`crops[${index}].yield.size`}
            name={`crops[${index}].yield.size`}
            options={sizeOptions}
            required={true}
            showLegend={false}
          />
          {images ? (
            <ImageInput
              maxSize={1000}
              key={`crops[${index}].yield.size_images`}
              name={`crops[${index}].yield.size_images`}
              label={t("crop.imagesForYieldSize", "Image of pegged subplot")}
              required={true}
              startingValue={crops[index].yield.size_images}
            />
          ) : null}

          <NumericInput
            label={t("crop.yieldDiagonal", "Length of subplot diagonal")}
            key={`crops[${index}].yield.diagonal`}
            name={`crops[${index}].yield.diagonal`}
            required={true}
          />
          {images ? (
            <ImageInput
              maxSize={1000}
              key={`crops[${index}].yield.diagonal_images`}
              name={`crops[${index}].yield.diagonal_images`}
              label={t(
                "crop.imagesForYieldDiagonal",
                "Image of diagonal measurement",
              )}
              required={true}
              startingValue={crops[index].yield.diagonal_images}
            />
          ) : null}

          {images ? (
            <ImageInput
              maxSize={1000}
              key={`crops[${index}].yield.harvested_images`}
              name={`crops[${index}].yield.harvested_images`}
              label={t(
                "crop.imagesForYieldHarvested",
                "Image of harvested subplot",
              )}
              required={true}
              startingValue={crops[index].yield.harvested_images}
            />
          ) : null}

          {isGrain(crops[index].crop) ? (
            <>
              {isCorn(crops[index].crop) && (
                <>
                  <NumericInput
                    label={t(
                      "crop.yieldNumPlants",
                      "Number of plants in the subplot",
                    )}
                    key={`crops[${index}].yield.n_plants`}
                    name={`crops[${index}].yield.n_plants`}
                    required={true}
                  />
                  <NumericInput
                    label={t(
                      "crop.yieldNumCobs",
                      "Number of cobs in the subplot",
                    )}
                    key={`crops[${index}].yield.n_cobs`}
                    name={`crops[${index}].yield.n_cobs`}
                    required={true}
                  />
                </>
              )}
              <Select
                label={t(
                  "crop.yieldMoistureMeter",
                  "Are you using a moisture meter?",
                )}
                key={`crops[${index}].yield.using_moisture`}
                name={`crops[${index}].yield.using_moisture`}
                options={yesNoOptions}
                required={true}
                showLegend={false}
              />

              {crops[index].yield?.using_moisture === "yes" ? (
                <>
                  <NumericInput
                    label={t("crop.yieldGrains", "Recorded grains weight (kg)")}
                    key={`crops[${index}].yield.yield`}
                    name={`crops[${index}].yield.yield`}
                    required={true}
                  />
                  {images ? (
                    <ImageInput
                      maxSize={1000}
                      key={`crops[${index}].yield.yield_images`}
                      name={`crops[${index}].yield.yield_images`}
                      label={t(
                        "crop.imagesForYield",
                        "Image of yield measurement",
                      )}
                      required={true}
                      startingValue={crops[index].yield.yield_images}
                    />
                  ) : null}
                  <NumericInput
                    label={t(
                      "crop.moistureLevel",
                      "Moisture level on 100g sample (%)",
                    )}
                    key={`crops[${index}].yield.moisture`}
                    name={`crops[${index}].yield.moisture`}
                    required={true}
                  />
                  {images ? (
                    <ImageInput
                      maxSize={1000}
                      key={`crops[${index}].yield.moisture_images`}
                      name={`crops[${index}].yield.moisture_images`}
                      label={t(
                        "crop.imagesForMoisture",
                        "Image of moisture measurement",
                      )}
                      required={true}
                      startingValue={crops[index].yield.moisture_images}
                    />
                  ) : null}
                </>
              ) : crops[index].yield?.using_moisture === "no" ? (
                <>
                  <NumericInput
                    label={t("crop.yieldW1", "W1: Bag weight (kg)")}
                    key={`crops[${index}].yield.yield_w1`}
                    name={`crops[${index}].yield.yield_w1`}
                    required={true}
                  />
                  {images ? (
                    <ImageInput
                      maxSize={1000}
                      key={`crops[${index}].yield.yield_w1_images`}
                      name={`crops[${index}].yield.yield_w1_images`}
                      label={t(
                        "crop.imagesForYieldW1",
                        "Image of bag weight on the scale",
                      )}
                      required={true}
                      startingValue={crops[index].yield.yield_w1_images}
                    />
                  ) : null}
                  {isCorn(crops[index].crop) && (
                    <>
                      <NumericInput
                        label={t(
                          "crop.yieldW2",
                          "W2: Bag + dehusked maize weight (kg)",
                        )}
                        key={`crops[${index}].yield.yield_w2`}
                        name={`crops[${index}].yield.yield_w2`}
                        required={true}
                      />
                      {images ? (
                        <ImageInput
                          maxSize={1000}
                          key={`crops[${index}].yield.yield_w2_images`}
                          name={`crops[${index}].yield.yield_w2_images`}
                          label={t(
                            "crop.imagesForYieldW2",
                            "Image of bag + dehusked maized weight on the scale",
                          )}
                          required={true}
                          startingValue={crops[index].yield.yield_w2_images}
                        />
                      ) : null}
                    </>
                  )}
                  <NumericInput
                    label={t("crop.yieldW3", "W3: Bag + grains weight (kg)")}
                    key={`crops[${index}].yield.yield_w3`}
                    name={`crops[${index}].yield.yield_w3`}
                    required={true}
                  />
                  {images ? (
                    <ImageInput
                      maxSize={1000}
                      key={`crops[${index}].yield.yield_w3_images`}
                      name={`crops[${index}].yield.yield_w3_images`}
                      label={t(
                        "crop.imagesForYieldW3",
                        "Image of bag + grains weight on the scale",
                      )}
                      required={true}
                      startingValue={crops[index].yield.yield_w3_images}
                    />
                  ) : null}

                  <NumericInput
                    label={t(
                      "crop.yieldW4",
                      "W4: Weight of sample before drying (kg). Enter 3 digits (e.g., 0.201)",
                    )}
                    key={`crops[${index}].yield.yield_w4`}
                    name={`crops[${index}].yield.yield_w4`}
                    required={true}
                  />
                  {images ? (
                    <ImageInput
                      maxSize={1000}
                      key={`crops[${index}].yield.yield_w4_images`}
                      name={`crops[${index}].yield.yield_w4_images`}
                      label={t(
                        "crop.imagesForYieldW4",
                        "Image of weight on the scale before drying",
                      )}
                      required={true}
                      startingValue={crops[index].yield.yield_w4_images}
                    />
                  ) : null}
                  {images ? (
                    <ImageInput
                      maxSize={1000}
                      key={`crops[${index}].yield.yield_w4_drying_images`}
                      name={`crops[${index}].yield.yield_w4_drying_images`}
                      label={t(
                        "crop.imagesForYieldW4Drying",
                        "Image of grains drying next to bag",
                      )}
                      required={true}
                      startingValue={crops[index].yield.yield_w4_drying_images}
                    />
                  ) : null}

                  <NumericInput
                    label={t(
                      "crop.yieldW5",
                      "W5: Weight of sample after drying (kg). Enter 3 digits (e.g., 0.082)",
                    )}
                    key={`crops[${index}].yield.yield_w5`}
                    name={`crops[${index}].yield.yield_w5`}
                    required={true}
                  />
                  {images ? (
                    <ImageInput
                      maxSize={1000}
                      key={`crops[${index}].yield.yield_w5_images`}
                      name={`crops[${index}].yield.yield_w5_images`}
                      label={t(
                        "crop.imagesForYieldW5",
                        "Image of weight on the scale after drying",
                      )}
                      required={true}
                      startingValue={crops[index].yield.yield_w5_images}
                    />
                  ) : null}
                </>
              ) : null}
            </>
          ) : (
            <>
              <NumericInput
                label={t("crop.yield", "Recorded yield (kg)")}
                key={`crops[${index}].yield.yield`}
                name={`crops[${index}].yield.yield`}
                required={true}
              />
              {images ? (
                <ImageInput
                  maxSize={1000}
                  key={`crops[${index}].yield.yield_images`}
                  name={`crops[${index}].yield.yield_images`}
                  label={t("crop.imagesForYield", "Image of yield measurement")}
                  required={true}
                  startingValue={crops[index].yield.yield_images}
                />
              ) : null}
            </>
          )}
        </>
      )}
    </>
  );
}
export default withTranslation()(YieldInput);
