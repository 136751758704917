import { useEffect, useState } from "react";
import { register } from "./serviceWorkerRegistration.js";

export function RegisterServiceWorker() {
  const [registration, setRegistration] = useState(null);

  useEffect(() => {
    // Register the service worker with a callback if update is available
    register({ onUpdate: setRegistration });
  }, []);

  function update() {
    // User has confirmed update to new version, so promote new service worker
    // and reload the page
    // Ref https://stackoverflow.com/a/66733379
    registration.waiting?.postMessage({ type: "SKIP_WAITING" });
    window.location.reload();
  }

  return (
    registration && (
      <div className="fixed inset-x-0 bottom-0 pb-2 sm:pb-5">
        <div className="mx-auto max-w-xl px-2 sm:px-6 lg:px-8">
          <div className="rounded-lg bg-cyan-600 p-2 shadow-lg sm:p-3">
            <div className="flex flex-wrap items-center justify-between">
              <div className="flex w-0 flex-1 items-center">
                <p className="ml-3 truncate font-medium text-white">
                  App update available
                </p>
              </div>
              <div className="flex-shrink-0 order-2 mt-0 w-auto">
                <button
                  onClick={update}
                  className="flex items-center justify-center rounded-md border border-transparent bg-white px-4 py-2 text-sm font-medium text-cyan-600 shadow-sm hover:bg-cyan-50"
                >
                  Update
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  );
}
