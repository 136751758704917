import { useEffect } from "react";
import { useNavigate, Redirect } from "react-router-dom";
import { withTranslation } from "react-i18next";

import { useUser } from "../../user/utils/user";

import DelineationHome from "../../delineation/home.js";
import Delineation2Home from "../../delineation2/home.js";
import DataHome from "../../data/home.js";
import LandcoverHome from "../../landcover/home.js";
import Landcover2Home from "../../landcover2/home.js";

const homeMap = {
  Data: { home: DataHome, default: "/data/task" },
  Delineation: { home: DelineationHome, default: "/delineation/task" },
  Delineation2: { home: Delineation2Home, default: "/delineation2/task" },
  Landcover: { home: LandcoverHome, default: "/landcover/task" },
  Landcover2: { home: Landcover2Home, default: "/landcover2/task" },
};

const defaultRole = "Data";

function Home({ showAlert, t }) {
  const navigate = useNavigate();
  const { user } = useUser();
  // Filter user roles to exclude invalid roles
  let roles = user?.user?.roles?.filter((role) => role in homeMap);
  // If the user has no valid roles or is not logged in, set role to Data so that users can still collect Data
  roles = roles && roles.length > 0 ? roles : [defaultRole];

  useEffect(() => {
    // If there is only one role, go straight into that
    if (roles.length === 1) {
      navigate(homeMap[roles[0]].default);
    }
  }, [roles, navigate]);

  if (roles.length > 1) {
    // Show home elements for each task the user can access
    const children = roles.map((r) => {
      const HomeElement = homeMap[r]?.home;
      return HomeElement ? <HomeElement showAlert={showAlert} t={t} /> : null;
    });
    return (
      <div className="text-center">
        <h1 className="text-xl font-semibold text-gray-900">Choose a task</h1>
        <div className="mt-6">
          {children.map((c, i) => (
            <div className="mt-4" key={i}>
              {c}
            </div>
          ))}
        </div>
      </div>
    );
  }
}

export default withTranslation()(Home);
